<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Users</div>
      <ul v-if="users_counts" class="header-nav">
        <router-link tag="li" active-class="is-active" to="/users/all"><span>All</span><span class="nv-badge">{{users_counts.total}}</span></router-link>
        <router-link tag="li" active-class="is-active" to="/users/new"><span>New</span><span class="nv-badge">{{users_counts.new}}</span></router-link>
        <router-link tag="li" active-class="is-active" to="/users/coaches"><span>Coaches</span><span class="nv-badge">{{users_counts.coaches}}</span></router-link>
        <router-link tag="li" active-class="is-active" to="/users/mentors"><span>Mentors</span><span class="nv-badge">{{users_counts.mentors}}</span></router-link>
        <router-link tag="li" active-class="is-active" to="/users/clients"><span>Clients</span><span class="nv-badge">{{users_counts.clients}}</span></router-link>
      </ul>
      <!--
      <div class="header-rg">
        <button
          class="btn btn-primary"
        >
          Add user
        </button>
      </div>
      -->
    </header>
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    users_counts: function () {
      return this.$store.getters.counts_users
    }
  },
  methods: {},
  mounted () {
    this.$store.dispatch('fetchCountsUsers')
  },
  watch: {},
  components: {}
}
</script>
