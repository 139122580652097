import axios from 'axios'

export default {
  state: {
    professions: ''
  },
  mutations: {
    updateProfessions (state, payload) {
      state.professions = payload
    }
  },
  actions: {
    async fetchProfessions ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getprofessionlist',
          params: {
            page: payload.current_page
          }
        })
        commit('updateProfessions', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    }
    // async sendProfessions ({ commit, getters }, payload) {
    //   // console.log(payload)
    //   // commit('clearError')
    //   // try {
    //   //   let s = ''
    //   //   if (payload.imageUpload) {
    //   //     s = await axios.post(getters.baseURL + 'apicall/admin/storematerialphoto', payload.imageUpload, {
    //   //       headers: {
    //   //         'Content-Type': 'multipart/form-data',
    //   //         Authorization: 'Bearer ' + getters.userSecurity.token,
    //   //         'X-Api-Key': getters.userSecurity.key
    //   //       }
    //   //     })
    //   //   }
    //   //   const d = {
    //   //     id: 0,
    //   //     title: payload.title,
    //   //     type: payload.type,
    //   //     source: payload.type === 'course' ? payload.source : '',
    //   //     author: payload.author,
    //   //     description: payload.description,
    //   //     image: s ? s.data.path : '',
    //   //     link: payload.link,
    //   //     tags: payload.tags.map(e => e.id),
    //   //     level: +payload.level,
    //   //     rating: payload.rating,
    //   //     reviwes: +payload.reviews
    //   //   }
    //   //   console.log(d)
    //   //   const v = await axios({
    //   //     method: 'post',
    //   //     headers: {
    //   //       Authorization: 'Bearer ' + getters.userSecurity.token,
    //   //       'X-Api-Key': getters.userSecurity.key
    //   //     },
    //   //     url: getters.baseURL + 'apicall/admin/savematerial',
    //   //     params: {
    //   //       data: JSON.stringify(d)
    //   //     }
    //   //   })
    //   //   console.log(v)
    //   //   // commit('updateBasicprofile', v.data)
    //   // } catch (error) {
    //   //   console.error(error.response)
    //   //   commit('setError', error)
    //   // }
    // }
  },
  getters: {
    professions (state) {
      return state.professions
    },
    one_profession: (state) => (id) => {
      return state.professions.data.find((e) => {
        return e.id === id
      })
    }
  }
}
