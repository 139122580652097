<template>
  <div class="aside-nav br-right">
    <router-link to="/" tag="div" class="aside-nav_logo"><img src="@/assets/img/logo-min.svg" alt="logo"></router-link>
    <ul class="aside-nav_top">
      <router-link active-class="is-active" :class="{'is-active': subIsActive('/dashboard')}" to="/" tag="li" class="svg-icon-hover" exact><span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 2H3a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V3a1 1 0 00-1-1z" class="fill-dark-grey"/><path d="M10 13H3a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1zM21 2h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V3a1 1 0 00-1-1zM21 13h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1z" class="fill-light-grey"/></svg></span></router-link>
      <router-link active-class="is-active" :class="{'is-active': subIsActive('/users')}" to="/users/all" tag="li" class="svg-icon-hover"><span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.949 14.54c-3.45 0-6.361.564-6.361 2.74S8.518 20 11.948 20c3.45 0 6.362-.564 6.362-2.74s-2.93-2.72-6.361-2.72z" class="fill-dark-grey"/><path d="M11.949 12.467a4.207 4.207 0 004.21-4.233A4.206 4.206 0 0011.948 4a4.206 4.206 0 00-4.21 4.234 4.207 4.207 0 004.21 4.233zM21.088 9.22c.604-2.378-1.168-4.513-3.424-4.513-.245 0-.48.027-.71.072-.03.008-.064.023-.082.05-.02.034-.005.08.018.11a5.807 5.807 0 011.067 3.37 5.762 5.762 0 01-.984 3.242.158.158 0 00.106.244c.158.028.32.042.484.047 1.643.043 3.118-1.02 3.525-2.623z" class="fill-light-grey"/><path d="M22.81 14.817c-.301-.645-1.028-1.087-2.132-1.304-.52-.128-1.931-.308-3.243-.284-.02.003-.03.017-.032.026-.003.012.003.034.029.047.606.302 2.95 1.614 2.655 4.381-.013.12.083.224.202.206.576-.083 2.06-.404 2.52-1.402a1.922 1.922 0 000-1.67z" class="fill-dark-grey"/><path d="M7.045 4.78a3.514 3.514 0 00-.71-.073c-2.256 0-4.028 2.135-3.423 4.512.407 1.603 1.882 2.666 3.525 2.623.164-.005.326-.02.483-.047a.158.158 0 00.107-.244 5.764 5.764 0 01-.984-3.241c0-1.251.39-2.415 1.068-3.37.021-.03.037-.077.016-.11-.018-.029-.051-.043-.082-.05z" class="fill-light-grey"/><path d="M3.322 13.513c-1.104.217-1.83.659-2.13 1.304a1.918 1.918 0 000 1.67c.46.998 1.943 1.32 2.52 1.402.119.018.214-.085.201-.206-.294-2.766 2.05-4.078 2.656-4.38.025-.014.03-.035.028-.049-.002-.009-.012-.022-.031-.024-1.313-.025-2.722.155-3.244.283z" class="fill-dark-grey"/></svg></span></router-link>
      <router-link active-class="is-active" to="/teaching-material" tag="li" class="svg-icon-hover"><span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.2 2H7.8C4.8 2 3 3.8 3 6.8v10.4c0 3 1.8 4.8 4.8 4.8h8.4c3 0 4.8-1.7 4.8-4.8V6.8c0-3-1.7-4.8-4.8-4.8z" class="fill-light-grey"/><path d="M8 15.7h8c.3 0 .6.4.6.8s-.3.8-.7.8H8.1a.8.8 0 01-.8-.4.8.8 0 01.8-1.2zm8-4.5a.8.8 0 010 1.5H8a.8.8 0 010-1.5h8zm-5-4.6c.5 0 .8.4.8.8 0 .5-.3.8-.7.8H8a.8.8 0 010-1.5h3z" class="fill-dark-grey"/></svg></span></router-link>
      <router-link active-class="is-active" :class="{'is-active': subIsActive('/jobs')}" to="/jobs/professions" tag="li" class="svg-icon-hover"><span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 6H5a3 3 0 00-3 3v2.72L8.837 14h6.326L22 11.72V9a3 3 0 00-3-3z" class="fill-light-grey"/><path d="M10 6V5h4v1h2V5a2.002 2.002 0 00-2-2h-4a2.002 2.002 0 00-2 2v1h2zm-1.163 8L2 11.72V18a3.003 3.003 0 003 3h14a3.003 3.003 0 003-3v-6.28L15.163 14H8.837z" class="fill-dark-grey"/></svg></span></router-link>
    </ul>
    <div class="aside-nav_bottom">
      <ul class="menu">
        <li>
          <a class="svg-icon-hover" href="#">
            <span class="iocn"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 12c0 5.524-4.477 10-10 10S2 17.524 2 12C2 6.478 6.477 2 12 2s10 4.478 10 10z" class="fill-light-grey"/><path d="M8.615 9.665c0-.433.14-.87.417-1.312.278-.447.684-.816 1.217-1.108.533-.292 1.155-.437 1.866-.437.661 0 1.244.123 1.75.369.506.241.896.572 1.17.991.277.42.416.875.416 1.367 0 .388-.08.727-.24 1.019a3.064 3.064 0 01-.56.758c-.214.21-.601.566-1.162 1.067-.155.141-.28.267-.376.376a1.445 1.445 0 00-.314.56c-.023.087-.06.242-.11.465-.086.474-.357.711-.813.711a.844.844 0 01-.602-.232c-.16-.155-.239-.385-.239-.69 0-.384.06-.714.178-.992.118-.282.276-.528.472-.738.195-.214.46-.467.792-.759.292-.255.502-.447.63-.574.132-.132.241-.278.328-.438a1.03 1.03 0 00.136-.52c0-.364-.136-.671-.41-.922-.269-.25-.617-.376-1.046-.376-.501 0-.87.128-1.107.383-.237.25-.438.622-.602 1.114-.155.515-.449.773-.882.773a.884.884 0 01-.649-.267c-.173-.182-.26-.378-.26-.588zm3.336 7.492c-.278 0-.522-.089-.731-.266-.205-.183-.308-.436-.308-.76 0-.286.1-.528.3-.724.201-.196.447-.294.74-.294.286 0 .528.098.724.294a.985.985 0 01.294.725c0 .319-.103.57-.308.752a1.038 1.038 0 01-.71.273z" class="fill-dark-grey light"/></svg></span>
          </a>
        </li>
        <li>
          <a class="svg-icon-hover" href="#">
            <span class="iocn"><svg width="24" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.77 14.645c-.731-.853-1.063-1.592-1.063-2.848v-.427c0-1.636-.377-2.69-1.195-3.745C16.248 5.987 14.124 5 12.044 5h-.088c-2.037 0-4.095.942-5.379 2.513-.864 1.075-1.284 2.175-1.284 3.857v.427c0 1.256-.31 1.995-1.063 2.848-.553.629-.73 1.436-.73 2.31 0 .876.287 1.705.864 2.379a4.532 4.532 0 002.9 1.413c1.571.179 3.142.246 4.737.246 1.593 0 3.164-.112 4.736-.246a4.532 4.532 0 002.9-1.413 3.614 3.614 0 00.863-2.378c0-.875-.177-1.682-.73-2.31z" class="fill-light-grey"/><path d="M14.009 22.228c-.5-.106-3.546-.106-4.046 0-.428.1-.89.329-.89.832.025.48.306.905.696 1.174h-.001a3.636 3.636 0 001.714.733c.33.045.666.043 1.008 0 .618-.09 1.21-.34 1.714-.732l-.001-.001c.39-.27.67-.693.695-1.174 0-.503-.462-.733-.89-.832z" class="fill-dark-grey"/><circle cx="18" cy="5" r="4" fill="#FA665B" stroke="#fff" stroke-width="2"/></svg></span>
          </a>
        </li>
      </ul>
      <div class="image-user">
        <img src="@/assets/img/image-user-02.jpg" alt="image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    subIsActive: function (v) {
      const paths = Array.isArray(v) ? v : [v]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    }
  }
}
</script>
