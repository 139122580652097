<template>
  <div v-if="content">
    <ul class="tabs-nav type-two">
      <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">General information</li>
      <!--
      <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">Statistics</li>
      -->
      <!--
      <li @click.prevent="setActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">Activity</li>
      -->
      <li v-if="content.profile" @click.prevent="setActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">Profile</li>
      <li @click.prevent="setActive('tab-5')" :class="{ 'is-active': isActive('tab-5') }">Settings</li>
    </ul>
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">
      <div class="lb-mb-20">
        <div class="h3 lb-mb-2">About person</div>
        <div class="color-grey lb-mb-15">Basic information about Cliffton Wells</div>
        <div class="more-info-layout">
          <table class="table table-mod text-md">
            <tbody>
            <tr>
              <td>Balance</td>
              <td><span class="text-media color-dark">€{{content.general.balance}}</span></td>
            </tr>
            <tr>
              <td>Account</td>
              <td><span class="badge-ml sm bg-green">{{content.general.account}}</span></td>
            </tr>
            <tr>
              <td>Date of registration</td>
              <td><span class="text-media color-dark">{{dateDay(content.general.created).date}}<span class="color-grey-2"> • </span>{{dateDay(content.general.created).hours}} : {{dateDay(content.general.created).minutes}}</span></td>
            </tr>
            <tr>
              <td>Last activity</td>
              <td><span class="text-media color-dark">{{dateDay(content.general.last_active).date}}<span class="color-grey-2"> • </span>{{dateDay(content.general.last_active).hours}} : {{dateDay(content.general.last_active).minutes}}</span></td>
            </tr>
            <tr>
              <td>Assign a coach</td>
              <td>
                <div v-if="content.general.coaches.length > 0" class="block-user-wrapper">
                  <div
                    class="block-user"
                    v-for="(item, i) in content.general.coaches"
                    :key="i + 'ut'"
                  >
                    <div class="image">
                      <img v-if="item.photo" :src="item.photo" alt="image">
                      <img v-else src="@/assets/img/default-user-avatar.png" alt="iamge">
                    </div>
                    <div class="name text-media color-dark">{{item.name}}</div>
                  </div>
                </div>
                <div v-else>None</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--
      <div>
        <div class="h3 lb-mb-2">User activity</div>
        <div class="color-grey lb-mb-15">Displaying user activity: studied materials, meetings, goals fulfillment.</div>
        <div class="more-info-layout">
          <line-chart-more-info
            :info="info"
            color-border-chart="#02BB9F"
            color-bg-chart="rgba(1,1,1,0)"
          >
          </line-chart-more-info>
        </div>
      </div>
      -->
    </div>
    <!--
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">
      <div class="lb-mb-20">
        <div class="lb-mb-15">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto">
              <div class="h3">Meeting statistics</div>
            </div>
            <div class="col-auto">
              <div class="btn-group bg-gray">
                <button class="btn btn-ghost dark-gray active">Today</button>
                <button class="btn btn-ghost dark-gray">Upcoming</button>
                <button class="btn btn-ghost dark-gray">Past</button>
              </div>
            </div>
          </div>
        </div>
        <div class="more-info-layout">
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
        </div>
      </div>
      <div class="lb-mb-8">
        <template v-if="content.roadmap">
          <timeline-plan-dev
            :plan="content.roadmap"
          ></timeline-plan-dev>
        </template>
      </div>
      <div class="text-sm color-grey">Roadmap completed: <span class="color-green2">56%</span></div>
    </div>
    -->
    <!--
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">
      <div class="lb-mb-15">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <div class="h3">Support activity</div>
          </div>
          <div class="col-auto">
            <div class="btn-group bg-gray">
              <button class="btn btn-ghost dark-gray active">Today</button>
              <button class="btn btn-ghost dark-gray">Past</button>
            </div>
          </div>
        </div>
      </div>
      <div class="thumbnail-supp text-sm green">
        <div class="head">
          <div class="status">
            Open
          </div>
          <div class="time">02 Jun, 2021 / 02:33 PM</div>
        </div>
        <div class="lb-mb-8"><span>No. Ticket:</span> <span class="color-dark">#934587345347-23</span></div>
        <div class="lb-mb-8"><span>Theme:</span> <span class="color-dark">Don’t show Roadmap</span></div>
        <div><span>Message:</span> <span class="color-dark">Hello, I just signed up. I went to view the roadmap, but I get an error</span></div>
      </div>
      <div class="thumbnail-supp text-sm red">
        <div class="head">
          <div class="status">
            Closed
          </div>
          <div class="time">02 Jun, 2021 / 02:33 PM</div>
        </div>
        <div class="lb-mb-8"><span>No. Ticket:</span> <span class="color-dark">#934587345347-23</span></div>
        <div class="lb-mb-8"><span>Theme:</span> <span class="color-dark">Don’t show Roadmap</span></div>
        <div><span>Message:</span> <span class="color-dark">Hello, I just signed up. I went to view the roadmap, but I get an error</span></div>
      </div>
    </div>
    -->
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">
      <div v-if="content.profile" class="lb-mb-20">
        <template v-if="!editProfileShow">
          <div class="more-info-head">
            <div class="text-in">
              <div class="h3 lb-mb-2">Main information</div>
              <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
            </div>
            <button
              class="btn btn-outlined"
              @click="editProfileShow = true"
            >
              Edit
            </button>
          </div>
          <div class="more-info-layout">
            <div class="info-user-wrapper">
              <div class="info">
                <div class="h2 lb-mb-15">{{content.profile.fullname}}</div>
                <div v-if="content.profile.current_job" class="lb-mb-15">{{content.profile.current_job}}</div>
                <table class="table table-mod  min-first-col text-md">
                  <tbody>
                  <tr v-if="content.profile.email">
                    <td>E-mail</td>
                    <td><span class="text-media color-dark">{{content.profile.email}}</span></td>
                  </tr>
                  <tr v-if="content.profile.phone">
                    <td>Phone number</td>
                    <td><span class="text-media color-dark">{{content.profile.phone}}</span></td>
                  </tr>
                  <tr v-if="content.profile.location">
                    <td>Location</td>
                    <td><span class="text-media color-dark">{{content.profile.location}}</span></td>
                  </tr>
                  <tr v-if="content.profile.lin">
                    <td>LinkedIn</td>
                    <td><a :href="content.profile.lin" class="btn btn-link">{{content.profile.lin}}</a></td>
                  </tr>
                  <tr v-if="content.profile.tw">
                    <td>Twitter</td>
                    <td><a :href="content.profile.tw" class="btn btn-link">{{content.profile.tw}}</a></td>
                  </tr>
                  <tr v-if="content.profile.fb">
                    <td>Facebook</td>
                    <td><a :href="content.profile.fb" class="btn btn-link">{{content.profile.fb}}</a></td>
                  </tr>
                  <tr v-if="content.profile.channel">
                    <td>Channel</td>
                    <td><span class="text-media color-dark">{{content.profile.channel}}</span></td>
                  </tr>
                  <tr v-if="content.profile.source">
                    <td>Source</td>
                    <td><span class="text-media color-dark">{{content.profile.source }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="image">
                <img v-if="content.profile.photo" :src="content.profile.photo" alt="image">
                <img v-else src="@/assets/img/default-user-avatar.png" alt="iamge">
              </div>
            </div>
          </div>
          <div v-if="content.information" class="lb-mb-20">
            <div class="h3 lb-mb-15">Information</div>
            <div class="more-info-layout">
              <table class="table table-mod min-first-col text-md">
                <tbody>
                <tr
                  v-for="(item, i) in content.information"
                  :key="i + 's'"
                >
                  <td>{{item.title}}</td>
                  <td>
                  <span class="text-media color-dark">
                    <span
                      class="info-list-c"
                      v-for="(option, a) in item.options.filter(e => e.selected === true)"
                      :key="a + 'c'"
                    >
                      {{option.value}}<span>,</span>
                    </span>
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="content.idealjob" class="lb-mb-20">
            <div class="h3 lb-mb-2">Ideal Job</div>
            <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
            <div class="more-info-layout">
              <table class="table table-mod min-first-col text-md">
                <tbody>
                <tr v-if="content.idealjob.empl_type">
                  <td>Employment Type</td>
                  <td><span class="text-media color-dark">{{empl_types.find((e) => e.id === content.idealjob.empl_type).title}}</span></td>
                </tr>
                <tr v-if="content.idealjob.position">
                  <td>Desired Position</td>
                  <td><span class="text-media color-dark">{{content.idealjob.position}}</span></td>
                </tr>
                <tr v-if="content.idealjob.salary_year || content.idealjob.salary_hour">
                  <td>Desired Salary</td>
                  <td>
                <span class="text-media color-dark">
                  <span v-if="content.idealjob.salary_year">${{content.idealjob.salary_year}} / year • </span><span v-if="content.idealjob.salary_hour">${{content.idealjob.salary_hour}} / hr</span>
                </span>
                  </td>
                </tr>
                <tr v-if="content.idealjob.relocate">
                  <td>Willing to Relocate</td>
                  <td><span class="text-media color-dark">{{content.idealjob.relocate}}</span></td>
                </tr>
                <tr v-if="content.idealjob.travel">
                  <td>Willing to Travel</td>
                  <td><span class="text-media color-dark">{{content.idealjob.travel}}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="content.credentials" class="lb-mb-20">
            <div class="h3 lb-mb-2">Professional Credentials</div>
            <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
            <div class="more-info-layout">
              <table class="table table-mod min-first-col text-md">
                <tbody>
                <tr v-if="content.credentials.experience">
                  <td>Experience</td>
                  <td><span class="text-media color-dark">{{content.credentials.experience}} years</span></td>
                </tr>
                <tr v-if="content.credentials.autorization">
                  <td>Work Autorization</td>
                  <td><span class="text-media color-dark">{{content.credentials.autorization}}</span></td>
                </tr>
                <tr v-if="content.credentials.security">
                  <td>Security Clearance</td>
                  <td><span class="text-media color-dark">{{content.credentials.security}}</span></td>
                </tr>
                <tr v-if="content.credentials.relocate">
                  <td>Willing to Relocate</td>
                  <td><span class="text-media color-dark">{{content.credentials.relocate}}</span></td>
                </tr>
                <tr v-if="content.credentials.cv_name">
                  <td>Resume</td>
                  <td>
                    <a
                      :href="content.credentials.cv_url"
                      class="btn btn-link"
                    >
                      <span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span>
                      <span>{{content.credentials.cv_name}}</span>
                    </a>
                  </td>
                </tr>
                <tr v-if="content.credentials.cover_name">
                  <td>Cover Letter</td>
                  <td>
                    <a
                      :href="content.credentials.cover_url"
                      class="btn btn-link"
                    >
                      <span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span>
                      <span>{{content.credentials.cover_name}}</span>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <template v-else>
          <more-info-client-edit
            :content="content"
            @close="closeInfoClients"
            @update="(id) => $emit('update', id)"
          ></more-info-client-edit>
        </template>
      </div>
    </div>
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-5')" :class="{ 'is-active': isActive('tab-5') }">
      <div class="lb-mb-32">
        <div class="h3 lb-mb-8">Status account</div>
        <div class="btn-wrapper sm">
          <button class="btn btn-secondary is-active">Active</button>
          <button class="btn btn-secondary">Inactive</button>
          <button class="btn btn-secondary">Block</button>
        </div>
      </div>
      <div class="lb-mb-32">
        <div class="h3 lb-mb-8">Delete account</div>
        <button
          class="btn btn-red"
          @click="confirmDeleteProfileShow = true"
        >
          Delete account
        </button>
      </div>
    </div>
    <pop-up
      :show-modal="confirmDeleteProfileShow"
      view-modal-container="modal-container"
      @closemodal="closeConfirm"
    >
      <div class="h2 lb-mb-20">Please confirm the deletion with<br> your password</div>
      <div class="input-wrapper-material lb-mb-32">
        <div
          class="input-inner"
          :class="{'typed': confirmCheckPassword}"
        >
          <input
            class="input input-material"
            name="confirmCheckPassword"
            type="password"
            @focus="focusInput"
            @blur="blurInput($event); $v.confirmCheckPassword.$touch()"
            @input="typedInput"
            @change="typedInput"
            v-model="confirmCheckPassword"
            :class="{'error': confirmError}"
          >
          <label>Password</label>
        </div>
        <div v-if="confirmError" class="input-error">{{confirmError}}</div>
      </div>
      <div class="btn-wrapper right">
        <button
          class="btn btn-secondary"
          @click="closeConfirm"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary btn-loader"
          :class="{'disable': $v.confirmCheckPassword.$error, 'loader': confirmDeleteProfileLoading}"
          @click="deleteProfile()"
        >
          Save
        </button>
      </div>
    </pop-up>
  </div>
</template>

<script>
// import TimelinePlanDev from '@/components/TimelinePlanDev'
// import LineChartMoreInfo from '@/components/LineChartMoreInfo'
import MoreInfoClientEdit from '@/components/MoreInfoClientEdit'
import { required } from 'vuelidate/lib/validators'
import PopUp from '@/components/PopUp'
export default {
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      activeItem: 'tab-1',
      /*
      info: [
        {
          month: 'Jan',
          quantity: 0
        },
        {
          month: 'Feb',
          quantity: 150
        },
        {
          month: 'Mar',
          quantity: 210
        },
        {
          month: 'Apr',
          quantity: 280
        },
        {
          month: 'May',
          quantity: 300
        },
        {
          month: 'Jun',
          quantity: 380
        }
      ],
      */
      empl_types: [
        { id: 1, title: 'Full Time / Office' },
        { id: 2, title: 'Full Time / Remote' },
        { id: 3, title: 'Part Time / Office' }
      ],
      editProfileShow: false,
      loading_userslist_client: false,
      confirmDeleteProfileShow: false,
      confirmDeleteProfileLoading: false,
      confirmCheckPassword: '',
      confirmError: ''
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    closeConfirm: function () {
      this.confirmDeleteProfileShow = false
      this.$v.confirmCheckPassword.$reset()
      this.confirmCheckPassword = ''
      this.confirmError = ''
    },
    deleteProfile: function () {
      this.$v.confirmCheckPassword.$touch()
      if (!this.$v.confirmCheckPassword.$error) {
        this.confirmDeleteProfileLoading = true
        this.$store.dispatch('deleteProfileClient', {
          password: this.confirmCheckPassword,
          user_id: this.content.profile.cl_id
        })
          .then((r) => {
            this.confirmDeleteProfileLoading = false
            if (r.data.status) {
              this.confirmError = ''
              this.confirmDeleteProfileShow = false
              this.$emit('update-list')
              setTimeout(() => {
                this.$emit('close')
              }, 500)
            } else {
              this.confirmError = r.data.message
            }
          })
          .catch((e) => {
            this.confirmDeleteProfileLoading = false
            console.log(e)
          })
      }
    },
    isActive (e) {
      return this.activeItem === e
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    dateDay (e) {
      const t = new Date(e)
      const date = ('0' + t.getDate()).slice(-2) + ' ' + this.getNameMonth(t.getMonth()) + ', ' + t.getFullYear()
      const d = {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth()),
        hours: ('0' + t.getHours()).slice(-2),
        minutes: ('0' + t.getMinutes()).slice(-2)
      }
      return d
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    },
    closeInfoClients () {
      this.editProfileShow = false
    }
  },
  validations: {
    confirmCheckPassword: {
      required
    }
  },
  components: {
    // TimelinePlanDev,
    MoreInfoClientEdit,
    PopUp
    // LineChartMoreInfo
  }
}
</script>

<style lang="scss">
.info-list-c:last-child{
  span{
    display: none;
  }
}
.block-user{
  display: flex;
  align-items: center;
  .image{
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name{
    padding-left: 8px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-wrapper{
    .block-user{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.more-info{
  &-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-in{
      margin-right: 15px;
    }
  }
  &-layout{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 16px;
  }
 }
.table.table-mod{
  tr:first-child{
    td{
      padding-top: 0;
    }
  }
  tr:last-child{
    td{
      padding-bottom: 0;
    }
  }
}
.info-user-wrapper{
  display: flex;
  .info{
    width: 100%;
    padding-right: 40px;
  }
  .image{
    width: 120px;
    height: 120px;
    flex: 0 0 120px;
    border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
.thumbnail-supp{
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-bottom: 4px;
  .head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .status{
    position: relative;
    padding-left: 12px;
    max-width: 40%;
    margin-right: 15px;
    &:after{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #161616;
    }
  }
  .status,
  .time{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.green{
    .status{
      &:after{
        background-color: #02BB9F;
      }
    }
  }
  &.red{
    .status{
      &:after{
        background-color: #FA665B;
      }
    }
  }
}
.thumbnail-stepper{
  padding-left: 18px;
  position: relative;
  padding-bottom: 24px;
  &:after{
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 0;
    bottom: -5px;
  }
  &:before{
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #5E4BF1;
    position: absolute;
    left: 0;
    top: 5px;
    transform: translateX(-50%);
    z-index: 2;
  }
  .title{
    margin-bottom: 8px;
  }
  &:last-child{
    padding-bottom: 0;
    &:after{
      display: none;
    }
  }
}
</style>
