import axios from 'axios'

export default {
  state: {
    user_counts: ''
  },
  mutations: {
    updateUserCounts (state, payload) {
      state.user_counts = payload
    }
  },
  actions: {
    async fetchUserCounts ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const r = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/usercounts',
          params: {
            period: payload
          }
        })
        commit('updateUserCounts', r.data)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    user_counts (state) {
      return state.user_counts
    }
  }
}
