<template>
  <div>
    <div v-if="posts">
      <div class="card-wrapper-negative">
        <div class="row row-32 card-wrap">
          <div v-for="(post, i) in posts" :key="i" class="col-lg-3">
            <div
              class="card"
              @click="$emit('click-card', {id: post.id})"
            >
              <div class="card-image">
                <img v-if="post.image" :src="post.image" alt="image">
                <img v-else src="@/assets/img/image-placeholder.jpg" alt="image">
              </div>
              <div class="lb-mb-8">
                <div class="card-info">
                  <div class="tags">
                    <div
                      class="badge-dark-wrap"
                      v-if="post.tags.length > 0"
                    >
                      <span class="badge-dark" v-for="(item, a) in post.tags" :key="a + 's'">{{item.skill}}</span>
                    </div>
                  </div>
                  <div v-if="post.source" class="platforms">
                    <span class="color-grey-2 text-sm">{{post.source}}</span>
                  </div>
                </div>
              </div>
              <div class="h4 lb-mb-8"><span class="max-l-a">{{post.title}}</span></div>
              <div class="text-md lb-mb-8"><span class="color-grey">Author: </span><span class="color-dark">{{post.author}}</span></div>
              <div class="card-rating text-md">
              <span v-if="post.rating" class="icon">
                <img v-if="post.rating < 3.5" src="@/assets/img/icons/icon-star-2.svg" alt="icon">
                <img v-else src="@/assets/img/icons/icon-star.svg" alt="icon">
              </span>
                <span v-if="post.rating" class="color-dark">{{post.rating}}</span>
                <span v-if="post.reviwes" class="color-grey"> ({{post.reviwes}} reviews)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['posts'],
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.card-wrapper-negative{
  margin-left: -8px;
  margin-right: -8px;
}
</style>
