<template>
  <div class="lab-chart-block">
    <div class="info">
      <div class="color-grey text-md title">{{title}}</div>
      <div
        class="h2 number"
        :class="colorNumber"
      >
        <slot name="number"></slot>
      </div>
    </div>
    <div class="chart" :style="{'max-width': widthChart + 'px'}">
      <bar-chart
        :chart-data="dataChart"
        :options="optionsChart"
      ></bar-chart>
    </div>
  </div>
</template>
<script>
import BarChart from '@/components/BarChart'
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    colorNumber: {
      type: String,
      default: 'dark'
    },
    info: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      optionsChart: {
        responsive: true,
        maintainAspectRatio: false,
        barRoundness: 1,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        },
        plugins: {
          title: {
            display: false
          }
        },
        scales: {
          xAxes: [{
            barPercentage: 1.0,
            barThickness: 4,
            maxBarThickness: 4,
            position: 'left',
            gridLines: {
              display: false,
              tickMarkLength: 0
            },
            ticks: {
              maxRotation: 10,
              display: false
            }
          }],
          yAxes: [{
            minBarLength: 4,
            gridLines: {
              display: false,
              tickMarkLength: 0
            },
            display: true,
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0
            }
          }]
        }
      }
    }
  },
  computed: {
    widthChart: function () {
      return this.info.length * 8
    },
    dataChart: function () {
      const l = []
      const d = []
      const c = []
      this.info.forEach((e, i) => {
        l.push('n-' + i)
        d.push(e)
        if (i === this.info.length - 1) {
          c.push('#02BB9F')
        } else {
          c.push('#FFAF3C')
        }
      })
      return {
        labels: l,
        datasets: [
          {
            label: 'Data One',
            backgroundColor: c,
            borderSkipped: false,
            data: d
          }
        ]
      }
    }
  },
  methods: {},
  components: {
    BarChart
  }
}
</script>

<style scoped lang="scss">
.lab-chart-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 16px;
  .title{
    margin-bottom: 2px;
  }
  .number{
    &.dark{
      color: #161616;
    }
    &.green{
      color: #02BB9F;
    }
  }
  .chart{
    max-width: 250px;
    position: relative;
    height: 50px;
    > div{
      position: relative;
      height: 50px;
    }
  }
}
</style>
