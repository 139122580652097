<template>
  <div>
    <users-list
      type="coach"
    ></users-list>
  </div>
</template>

<script>
import UsersList from '@/components/UsersList'
export default {
  components: {
    UsersList
  }
}
</script>
