<template>
  <div>
    <div
      id="content-block"
      class="sp-left sp-top"
      :class="{'sp-no': hideSide}"
    >
      <div class="container-cs">
        <aside-nav
          v-if="!hideSide"
        ></aside-nav>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import AsideNav from '@/components/AsideNav'

export default {
  data () {
    return {}
  },
  computed: {
    hideSide: function () {
      return this.$route.meta.hideSideNav
    }
  },
  methods: {},
  components: {
    AsideNav
  }
}
</script>

<style lang="scss">
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
#content-block.sp-no{
  padding: 0!important;
}
</style>
