<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Administration of works</div>
      <ul class="header-nav">
        <!--
        <router-link tag="li" active-class="is-active" to="/jobs/professions">
          <span>Professions</span>
          <span class="nv-badge">681</span>
        </router-link>
        <router-link tag="li" active-class="is-active" to="/jobs/vacancies"><span>Vacancies</span><span class="nv-badge">681</span></router-link>
        -->
      </ul>
      <div class="header-rg">
        <!--
        <button
          class="btn btn-primary"
          @click="newProfessions"
        >
          Add profession
        </button>
        -->
      </div>
    </header>
    <div class="thumbnail-professions-head text-media color-grey">
      <div class="check">
        <label class="checkbox-entry">
          <input
            type="checkbox"
            class="subscribe-input"
            v-model="professionsCheckedAll"
            :true-value="true"
            :false-value="false"
            @change="changeCheckedAll"
          ><span></span>
        </label>
      </div>
      <div class="name">
        <button
          class="btn btn-sort"
          @click="activeSort = 'name'"
        >
          <span>Name profession</span>
          <span class="icon"><svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 2l2 2 1-1-3-3-3 3 1 1 2-2zm0 8L2 8 1 9l3 3 3-3-1-1-2 2z" class="fill-grey"/></svg></span>
        </button>
      </div>
      <div class="type">
        <button
          class="btn btn-sort"
          @click="activeSort = 'type'"
        >
          <span>Type</span>
          <span class="icon"><svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 2l2 2 1-1-3-3-3 3 1 1 2-2zm0 8L2 8 1 9l3 3 3-3-1-1-2 2z" class="fill-grey"/></svg></span>
        </button>
      </div>
      <div class="skills">Required skills</div>
      <div class="salary">Salary Range</div>
    </div>
    <div v-if="!loading_professions">
      <div class="lb-bp-32">
        <div
          class="thumbnail-professions color-dark"
          v-for="(item, i) in professions"
          :key="i + 'x'"
        >
          <div class="check">
            <label class="checkbox-entry">
              <input
                type="checkbox"
                class="subscribe-input"
                :value="item"
                v-model="professionsChecked"
              >
              <span></span>
            </label>
          </div>
          <div class="name">
            {{item.full_name}}
          </div>
          <div class="type">
            {{item.category}}
          </div>
          <div class="skills">
            <div class="badge-ml-wrapper">
          <span
            class="badge-ml bg-grey2 sm"
            v-for="(skill, i) in item.skills.slice(0,3)"
            :key="i + 'q'"
          >
            {{skill.skill}}
          </span>
              <span
                class="badge-ml bg-gr1 rd2 sm"
                v-if="item.skills.length > 3"
              >
            +{{item.skills.length - 3}}
          </span>
            </div>
          </div>
          <div class="salary">
            €{{item.average_min_salary}} – €{{item.average_max_salary}}
          </div>
          <div class="info-more">
            <button
              class="btn btn-ghost btn-icon"
              @click="editProfessions(item)"
            >
              <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
            </button>
          </div>
        </div>
      </div>
      <div class="text-center lb-bp-32">
        <div class="pagination">
          <button
            class="btn btn-outlined prev"
            :class="{'disable': 1 >= professions_pages.current_page}"
            @click="professions_filter.current_page -= 1"
          >
            Prev
          </button>
          <ul>
            <li
              class="btn btn-outlined"
              v-if="professions_pages.current_page !== 1"
              @click="professions_filter.current_page = 1"
            >
              1
            </li>
            <li
              v-if="professions_pages.current_page > 2"
            >...</li>
            <li
              class="btn btn-outlined is-active"
            >
              {{professions_pages.current_page}}
            </li>
            <li
              v-if="professions_pages.current_page < (professions_pages.last_page - 1)"
            >...</li>
            <li
              class="btn btn-outlined"
              v-if="professions_pages.current_page !== professions_pages.last_page"
              @click="professions_filter.current_page = professions_pages.last_page"
            >
              {{professions_pages.last_page}}
            </li>
          </ul>
          <button
            class="btn btn-outlined next"
            :class="{'disable': professions_pages.last_page <= professions_pages.current_page}"
            @click="professions_filter.current_page += 1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeModal"
    >
      <div class="h2 color-dark lb-mb-40">Add profession</div>
      <div v-if="professionsEdit">
        <div class="h4 lb-mb-2">General information</div>
        <div class="text-md color-grey lb-mb-15">Fill in the required fields to describe the event</div>
        <div class="form-group md lb-mb-20">
          <div class="input-wrapper-material">
            <div
              class="input-inner"
              :class="{'typed': professionsEdit.full_name}"
            >
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.professionsEdit.full_name.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="professionsEdit.full_name"
                :class="{'error': $v.professionsEdit.full_name.$error}"
              >
              <label>Name</label>
            </div>
            <div v-if="$v.professionsEdit.full_name.$error" class="input-error">Error</div>
          </div>
          <div class="input-wrapper-material textarea">
            <div
              class="input-inner"
              :class="{'typed': professionsEdit.description}"
            >
                <textarea
                  class="input md input-material typed"
                  :class="{'error': $v.professionsEdit.description.$error}"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.professionsEdit.description.$touch()"
                  @input="typedInput"
                  v-model="professionsEdit.description"
                ></textarea>
              <label>Description</label>
            </div>
            <div v-if="$v.professionsEdit.description.$error" class="input-error">Error</div>
          </div>
          <div
            class="sl-select-wrapper-material"
            :class="{'typed': professionsEdit.category.length > 0}"
          >

            <multiselect
              v-model="professionsEdit.category"
              :options="optionsType"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder=""
            >
            </multiselect>
            <label>Type</label>
          </div>
          <div
            class="ml-select-wrapper-material"
            :class="{'typed': professionsEdit.skills.length > 0}"
          >
            <!-- :class="{'typed': professionsEdit.skills.length > 0}" -->
            <multiselect
              tag-placeholder="Add this as new tag"
              placeholder=""
              label="skill"
              track-by="id"
              v-model="professionsEdit.skills"
              :options="optionsTopic"
              :multiple="true"
              @search-change="searchTopic"
            >
              <template slot="noOptions">Enter your search keyword</template>
              <div slot="noResult">
                <div v-if="loading_topic" class="loader-wrapper p-0">
                  <div class="loader-circle sm">
                    <img src="@/assets/img/loader-icon.png" alt="loader">
                  </div>
                </div>
                <span v-else>
                    <span v-if="one_letter_topic">Enter your search keyword</span>
                    <span v-else>Oops! No elements found.</span>
                  </span>
              </div>
            </multiselect>
            <label>Tags</label>
          </div>
        </div>
        <div class="lb-mb-40">
          <div class="h4 lb-mb-2">Salary range</div>
          <div class="text-md color-grey lb-mb-15">Fill in the required fields to describe the event</div>
          <div class="row row-8">
            <div class="col-lg-4">
              <div class="input-wrapper-material">
                <div
                  class="input-inner"
                  :class="{'typed': professionsEdit.average_min_salary !== ''}"
                >
                  <input
                    class="input input-material"
                    name="name"
                    type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.professionsEdit.average_min_salary.$touch()"
                    @input="typedInput"
                    v-model="professionsEdit.average_min_salary"
                    :class="{'error': $v.professionsEdit.average_min_salary.$error}"
                  >
                  <label>From salary (€)</label>
                </div>
                <div v-if="$v.professionsEdit.average_min_salary.$error" class="input-error">Error</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="input-wrapper-material">
                <div
                  class="input-inner"
                  :class="{'typed': professionsEdit.average_max_salary !== ''}"
                >
                  <input
                    class="input input-material"
                    name="name"
                    type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.professionsEdit.average_max_salary.$touch()"
                    @input="typedInput"
                    v-model="professionsEdit.average_max_salary"
                    :class="{'error': $v.professionsEdit.average_max_salary.$error}"
                  >
                  <label>Before salary (€)</label>
                </div>
                <div v-if="$v.professionsEdit.average_max_salary.$error" class="input-error">Error</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': localLoading}"
            @click="sendEditProfessions"
          >
            Add profession
          </button>
          <button
            class="btn btn-secondary"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import PopUp from '@/components/PopUp'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
export default {
  data () {
    return {
      localLoading: false,
      isNewProfessions: false,
      optionsTopic: [],
      optionsType: ['Development', 'Management'],
      showModal: false,
      professionsChecked: [],
      professionsCheckedAll: false,
      activeSort: '',
      professionsModel: {
        full_name: '',
        description: '',
        category: '',
        skills: [],
        average_max_salary: '',
        average_min_salary: ''
      },
      professionsEdit: '',
      one_letter_topic: false,
      loading_topic: false,
      professions_filter: {
        current_page: 1
      },
      loading_professions: true
    }
  },
  computed: {
    professions_pages: function () {
      return this.$store.getters.professions
    },
    professions: function () {
      return this.$store.getters.professions.data
    }
    // professionsSort: function () {
    //   switch (this.activeSort) {
    //     case 'name': return this.professions.slice(0).sort(this.sortByName)
    //     case 'type': return this.professions.slice(0).sort(this.sortByType)
    //     default: return this.professions
    //   }
    // }
  },
  methods: {
    changeCheckedAll: function () {
      console.log(this.professionsCheckedAll)
      if (this.professionsCheckedAll) {
        this.professionsChecked = this.professions
      } else {
        this.professionsChecked = []
      }
    },
    sortByName: function (a, b) {
      return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
    },
    sortByType: function (a, b) {
      return (a.type.toLowerCase() > b.type.toLowerCase()) ? 1 : -1
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    searchTopic: function (s, i) {
      if (s.length === 1) {
        this.one_letter_topic = true
      } else {
        this.one_letter_topic = false
      }
      if (s.length >= 2) {
        this.loading_topic = true
        this.$axios({
          method: 'post',
          url: this.$root.$data.baseURL + 'client/skillsearch',
          params: {
            search: s
          }
        })
          .then((r) => {
            this.optionsTopic = r.data
            this.loading_topic = false
          })
          .catch(function (e) {
            this.loading_topic = false
          })
      } else {
        this.optionsTopic = []
        this.loading_topic = false
      }
    },
    newProfessions: function () {
      this.showModal = true
      this.isNewProfessions = true
      this.professionsEdit = this.professionsModel
      this.$utils.blockScroll()
    },
    editProfessions: function (e) {
      this.showModal = true
      this.isNewProfessions = false
      const c = this.$store.getters.one_profession(+e.id)
      this.professionsEdit = JSON.parse(JSON.stringify(c))
      this.$utils.blockScroll()
    },
    closeModal: function () {
      this.showModal = false
      this.isNewProfessions = false
      this.professionsEdit = this.professionsModel
      this.$utils.unBlockScroll()
    },
    sendEditProfessions: function () {
      if (this.isNewProfessions) {
        this.professions.push(this.professionsEdit)
      } else {
        this.professions[this.professionsEdit.index] = this.professionsEdit
      }
      this.showModal = false
    },
    fetch_professions: function (e) {
      this.loading_professions = true
      this.$store.dispatch('fetchProfessions', e)
        .finally(() => {
          this.loading_professions = false
        })
    }
  },
  mounted () {
    this.fetch_professions(this.professions_filter)
  },
  watch: {
    professions_filter: {
      handler: function (v) {
        this.fetch_professions(v)
      },
      deep: true
    },
    professionsChecked: function (v) {
      if (v.length === this.professions.length) {
        this.professionsCheckedAll = true
      } else {
        this.professionsCheckedAll = false
      }
    }
  },
  validations: {
    professionsEdit: {
      full_name: {
        required
      },
      description: {
        required
      },
      average_max_salary: {
        required
      },
      average_min_salary: {
        required
      }
    }
  },
  components: {
    PopUp,
    Multiselect
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../../assets/scss/_multiselect.scss";
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.thumbnail-professions-head{
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
}
.thumbnail-professions{
  background-color: #fff;
  border-radius: 8px;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  &:last-child{
    margin-bottom: 0;
  }
  .check{
    line-height: 0;
  }
  .info-more{
    margin-left: auto;
  }
  .check,
  .name,
  .type,
  .salary{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.thumbnail-professions-head,
.thumbnail-professions{
  .check{
    width: 100%;
    max-width: 22px;
    margin-right: 10px;
    line-height: 0;
  }
  .name{
    width: 100%;
    max-width: 25%;
    margin-right: 10px;
  }
  .type{
    width: 100%;
    max-width: 20%;
    margin-right: 10px;
  }
  .skills{
    width: 100%;
    max-width: 32%;
    margin-right: 10px;
  }
  .salary{
    width: 100%;
    max-width: 15%;
    margin-right: 10px;
  }
}
</style>
