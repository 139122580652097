<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Dashboard</div>
    </header>
    <div class="row row-32 justify-content-center">
      <div class="col-lg-8">
        <div class="lb-bp-32">
          <general-statistics></general-statistics>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="lb-bp-32 lab-chart-block-wrapper">
          <template v-if="!loading_today_activity">
            <template v-if="today_activity">
              <div class="h3 lb-mb-15">Today activity</div>
              <bar-chart-dashboard
                title="New clients"
                colorNumber="green"
                :info="today_activity.clientsbyhour"
              >
                <template v-slot:number>
                  +{{today_activity.clients}}
                </template>
              </bar-chart-dashboard>
              <bar-chart-dashboard
                title="New coaches"
                colorNumber="green"
                :info="today_activity.coachesbyhour"
              >
                <template v-slot:number>
                  +{{today_activity.coaches}}
                </template>
              </bar-chart-dashboard>
              <bar-chart-dashboard
                title="New mentors"
                colorNumber="green"
                :info="today_activity.mentorsbyhour"
              >
                <template v-slot:number>
                  +{{today_activity.mentors}}
                </template>
              </bar-chart-dashboard>
              <bar-chart-dashboard
                title="Transactions"
                colorNumber="dark"
                :info="today_activity.transactionsbyhour"
              >
                <template v-slot:number>
                  ${{today_activity.transactions}}
                </template>
              </bar-chart-dashboard>
            </template>
          </template>
          <div v-else class="loader-wrapper">
            <div class="loader-circle">
              <img src="@/assets/img/loader-icon.png" alt="loader">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-32 justify-content-center">
      <div class="col-lg-8">
        <div class="lb-bp-32">
          <professions-statistics></professions-statistics>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="lb-bp-32 lab-chart-block-ln-wrapper">
          <div class="lb-mb-8">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                <div class="h3">Tariff statistics</div>
              </div>
              <div class="col-auto">
                <div class="dropdown drop-select min-a right">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">2 weeks</button>
                  <ul class="dropdown-inner">
                    <li>option 1</li>
                    <li>option 2</li>
                    <li>option 3</li>
                    <li>option 4</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <line-chart-dashboard
            title="Free package"
            colorNumber="dark"
            colorBorderChart="#1BD809"
            colorBgChart="rgba(89, 241, 75, 0.12)"
            :info="freePackage"
          >
            <template v-slot:number>
              340
            </template>
          </line-chart-dashboard>
          <line-chart-dashboard
            title="Silver package"
            colorNumber="dark"
            colorBorderChart="#969696"
            colorBgChart="rgba(165, 165, 165, 0.12)"
            :info="freePackage"
          >
            <template v-slot:number>
              98
            </template>
          </line-chart-dashboard>
          <line-chart-dashboard
            title="Gold package"
            colorNumber="dark"
            colorBorderChart="#F1CD4B"
            colorBgChart="rgba(241, 205, 75, 0.12)"
            :info="freePackage"
          >
            <template v-slot:number>
              143
            </template>
          </line-chart-dashboard>
        </div>
      </div>
    </div>
    <!--
    <div class="lb-mb-15">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="h3">Support tickets</div>
        </div>
        <div class="col-auto">
          <div class="dropdown-wrapper sm">
            <div class="dropdown drop-select min-a">
              <span class="dropdown-overlay" @click="dropClose"></span>
              <button class="btn btn-outlined" @click="dropOpen">Status</button>
              <ul class="dropdown-inner">
                <li><label class="checkbox-entry"><input type="checkbox" value="In progress" class="subscribe-input" v-model="ticketsStatusCheck"><span>In progress</span></label></li>
                <li><label class="checkbox-entry"><input type="checkbox" value="New" class="subscribe-input" v-model="ticketsStatusCheck"><span>New</span></label></li>
              </ul>
            </div>
            <div class="dropdown drop-select right min-a">
              <span class="dropdown-overlay" @click="dropClose"></span>
              <button class="btn btn-outlined" @click="dropOpen">All priorities</button>
              <ul class="dropdown-inner">
                <li><label class="checkbox-entry"><input type="checkbox" value="Green" class="subscribe-input" v-model="ticketsPrioritiesCheck"><span>Green</span></label></li>
                <li><label class="checkbox-entry"><input type="checkbox" value="Orang" class="subscribe-input" v-model="ticketsPrioritiesCheck"><span>Orang</span></label></li>
                <li><label class="checkbox-entry"><input type="checkbox" value="Red" class="subscribe-input" v-model="ticketsPrioritiesCheck"><span>Red</span></label></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thumbnail-tickets-head text-md color-grey">
      <div class="check">
        <label class="checkbox-entry">
          <input
            type="checkbox"
            class="subscribe-input"
            v-model="ticketsCheckedAll"
            :true-value="true"
            :false-value="false"
            @change="changeCheckedAll"
          >
          <span></span>
        </label>
      </div>
      <div class="priority">
        Priority
      </div>
      <div class="user">
        Name
      </div>
      <div class="theme">
        Theme
      </div>
      <div class="status">
        Status
      </div>
      <div class="assign">
        Assign
      </div>
    </div>
    <div
      class="thumbnail-tickets"
      v-for="(item, i) in ticketsFilterPriorities"
      :key="i + 'g'"
    >
      <div class="check">
        <label class="checkbox-entry">
          <input
            type="checkbox"
            class="subscribe-input"
            :value="item"
            v-model="ticketsChecked"
          >
          <span></span>
        </label>
      </div>
      <div class="priority">
        <progress-lightning
          :number="+item.progressPriority"
        ></progress-lightning>
      </div>
      <div class="user">
        <div class="image">
          <img src="@/assets/img/image-user-022.jpg" alt="image">
        </div>
        <div class="name text-md color-dark">{{item.user.name}}</div>
      </div>
      <div class="theme">
        <span class="color-dark text-md text-media text-in">
          {{item.theme}}
        </span>
      </div>
      <div class="status">
        <span
          class="badge-ml sm"
          :class="{'bg-orang': item.status === 'In progress', 'bg-green': item.status === 'New'}"
        >
          {{item.status}}
        </span>
      </div>
      <div class="assign">
        <div class="image">
          <img src="@/assets/img/image-user-022.jpg" alt="image">
        </div>
        <div class="name text-md color-dark">{{item.assign.name}}</div>
      </div>
      <div class="info-more">
        <button class="btn btn-ghost btn-icon">
          <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
        </button>
      </div>
    </div>
    <div class="lb-tp-30"></div>
    -->
  </div>
</template>

<script>
/*
import ProgressLightning from '@/components/ProgressLightning'
 */
import BarChartDashboard from '@/components/BarChartDashboard'
import LineChartDashboard from '@/components/LineChartDashboard'
import GeneralStatistics from '@/components/GeneralStatistics'
import ProfessionsStatistics from '@/components/ProfessionsStatistics'
export default {
  data () {
    return {
      newClients: [
        {
          day: '05.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 12
        },
        {
          day: '08.06.2021',
          quantity: 14
        },
        {
          day: '09.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 12
        },
        {
          day: '08.06.2021',
          quantity: 14
        },
        {
          day: '09.06.2021',
          quantity: 8
        },
        {
          day: '01.07.2021',
          quantity: 18
        }
      ],
      newCoaches: [
        {
          day: '05.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 12
        },
        {
          day: '08.06.2021',
          quantity: 14
        },
        {
          day: '09.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        }
      ],
      newMentors: [
        {
          day: '05.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 12
        },
        {
          day: '08.06.2021',
          quantity: 14
        },
        {
          day: '05.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 12
        },
        {
          day: '08.06.2021',
          quantity: 14
        },
        {
          day: '09.06.2021',
          quantity: 8
        },
        {
          day: '06.06.2021',
          quantity: 10
        }
      ],
      transactions: [
        {
          day: '05.06.2021',
          quantity: 2
        },
        {
          day: '06.06.2021',
          quantity: 4
        },
        {
          day: '07.06.2021',
          quantity: 6
        }
      ],
      freePackage: [
        {
          day: '05.06.2021',
          quantity: 0
        },
        {
          day: '06.06.2021',
          quantity: 4
        },
        {
          day: '07.06.2021',
          quantity: 8
        },
        {
          day: '07.06.2021',
          quantity: 4
        },
        {
          day: '07.06.2021',
          quantity: 10
        },
        {
          day: '07.06.2021',
          quantity: 9
        },
        {
          day: '07.06.2021',
          quantity: 11
        },
        {
          day: '07.06.2021',
          quantity: 15
        }
      ],
      ticketsChecked: [],
      ticketsCheckedAll: false,
      supportTickets: [
        {
          progressPriority: 1,
          user: {
            name: 'Jonathan Winchester'
          },
          theme: 'How can I choose a coach?',
          status: 'In progress',
          assign: {
            name: 'Robert M.'
          }
        },
        {
          progressPriority: 4,
          user: {
            name: 'Jacob Jones'
          },
          theme: 'How can i choose a coach?',
          status: 'New',
          assign: {
            name: 'Marvin McKinney'
          }
        },
        {
          progressPriority: 6,
          user: {
            name: 'Bessie Cooper'
          },
          theme: 'How many coaches can I hire?',
          status: 'In progress',
          assign: {
            name: 'Marvin McKinney'
          }
        },
        {
          progressPriority: 4,
          user: {
            name: 'Albert Flores'
          },
          theme: 'How can i choose a coach?',
          status: 'In progress',
          assign: {
            name: 'Robert M.'
          }
        }
      ],
      ticketsStatusCheck: [],
      ticketsPrioritiesCheck: [],
      dataDoughnut: {
        labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [12, 20, 33, 12, 22],
            backgroundColor: ['red', 'orange', 'yellow', 'green', 'blue']
          }
        ]
      },
      loading_today_activity: false
    }
  },
  computed: {
    ticketsFilterStatus: function () {
      if (this.ticketsStatusCheck.length > 0) {
        return this.supportTickets.filter((item) => {
          const a = [item.status]
          return this.diff(this.ticketsStatusCheck, a)
        })
      } else {
        return this.supportTickets
      }
    },
    ticketsFilterPriorities: function () {
      this.ticketsFilterStatus.forEach((e) => {
        if (e.progressPriority >= 0 && e.progressPriority < 3) {
          e.priority = 'Green'
        } else if (e.progressPriority >= 3 && e.progressPriority < 6) {
          e.priority = 'Orang'
        } else if (e.progressPriority >= 6) {
          e.priority = 'Red'
        }
      })
      if (this.ticketsPrioritiesCheck.length > 0) {
        return this.ticketsFilterStatus.filter((item) => {
          const a = [item.priority]
          return this.diff(this.ticketsPrioritiesCheck, a)
        })
      } else {
        return this.ticketsFilterStatus
      }
    },
    today_activity: function () {
      return this.$store.getters.today_activity
    }
  },
  methods: {
    diff: function (a, b) {
      return a.filter(n => b.indexOf(n) > -1).length
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    changeCheckedAll: function () {
      if (this.ticketsCheckedAll) {
        this.ticketsChecked = this.ticketsFilterPriorities
      } else {
        this.ticketsChecked = []
      }
    },
    fetchTodayActivity: function () {
      if (this.today_activity === '') {
        this.loading_today_activity = true
      }
      this.$store.dispatch('fetchTodayActivity')
        .finally(() => {
          this.loading_today_activity = false
        })
    }
  },
  watch: {
    ticketsChecked: function (v) {
      if (v.length === this.ticketsFilterPriorities.length) {
        this.ticketsCheckedAll = true
      } else {
        this.ticketsCheckedAll = false
      }
    }
  },
  created () {
    if (this.$route.query.usr) {
      this.$router.push(this.$route.path)
    }
    this.fetchTodayActivity()
  },
  components: {
    // ProgressLightning,
    BarChartDashboard,
    LineChartDashboard,
    GeneralStatistics,
    ProfessionsStatistics
  }
}
</script>

<style lang="scss">
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
.lab-chart-block-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  .lab-chart-block{
    flex: 1;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.lab-chart-block-ln-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  .lab-chart-block-ln{
    flex: 1;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.lab-country{
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .item {
      margin-right: 16px;
      max-width: 25%;
      &:last-child {
        margin-right: 0;
      }
      .title {
        margin-bottom: 4px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .number {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  &-chart{
    height: 300px;
    > div{
      position: relative;
      height: 300px;
    }
  }
}
.lab-grafic-list{
  li{
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .circle{
    width: 8px;
    flex: 0 0 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .text-in{
    margin-right: 8px;
    max-width: 85%;
  }
  .lab-grafic-lf{
    display: flex;
    align-items: center;
    > span{
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.doughnut-chart{
  &-wrapper{
    max-width: 280px;
    position: relative;
    z-index: 0;
  }
  &-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 15px 40px;
    width: 100%;
    z-index: -1;
    .title{
      margin-bottom: 4px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

</style>
