<template>
  <div>
    <div class="lb-mb-20">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div v-if="type !== 'coach' && type !== 'mentor'" class="row row-8 align-items-center">
            <div class="col-auto">
              <span class="text-md color-grey">Filters:</span>
            </div>
            <!--
            <div class="col-auto">
              <div class="dropdown checkbox">
                <span class="dropdown-overlay" @click="dropClose"></span>
                <button class="btn btn-outlined" @click="dropOpen">Location</button>
                <ul class="dropdown-inner">
                  <li><label class="checkbox-entry"><input type="checkbox" value="UK, London" class="subscribe-input" v-model="inputLocation"><span>UK, London</span></label></li>
                  <li><label class="checkbox-entry"><input type="checkbox" value="USA, Los Angeles" class="subscribe-input" v-model="inputLocation"><span>USA, Los Angeles</span></label></li>
                  <li><label class="checkbox-entry"><input type="checkbox" value="USA, Atlanta" class="subscribe-input" v-model="inputLocation"><span>USA, Atlanta</span></label></li>
                </ul>
              </div>
            </div>
            -->
            <div class="col-auto">
              <div class="dropdown checkbox">
                <span class="dropdown-overlay" @click="dropClose"></span>
                <button class="btn btn-outlined" @click="dropOpen">Account</button>
                <ul class="dropdown-inner">
                  <li><label class="checkbox-entry"><input type="checkbox" value="Bronze" class="subscribe-input" v-model="userslist_filter.account"><span>Bronze</span></label></li>
                  <li><label class="checkbox-entry"><input type="checkbox" value="Silver" class="subscribe-input" v-model="userslist_filter.account"><span>Silver</span></label></li>
                  <li><label class="checkbox-entry"><input type="checkbox" value="Gold" class="subscribe-input" v-model="userslist_filter.account"><span>Gold</span></label></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="input-wrapper-icon clean">
            <span class="icon"><img src="@/assets/img/icons/icon-search-light.svg" alt="icon-search"></span>
            <button
              class="icon-clean"
              v-if="searchUsers.length"
              @click="searchUsers = ''"
            >
              <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
            </button>
            <input class="input light sm" placeholder="Search for name" type="text" v-model="searchUsers" @keypress.enter="run_search">
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="thumbnail-user-head text-media text-md color-grey">
        <div class="check"></div>
        <div class="name">
          <button
            class="btn btn-sort md"
            :class="{'active': userslist_filter.sort === 'name'}"
            @click="sort_change('name')"
          >
            <span>Name</span>
            <span class="icon"><svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 2l2 2 1-1-3-3-3 3 1 1 2-2zm0 8L2 8 1 9l3 3 3-3-1-1-2 2z" class="fill-grey"/></svg></span>
          </button>
        </div>
        <div class="location">Location</div>
        <template v-if="type !== 'coach'">
          <div class="account">Account</div>
        </template>
        <div class="role">
          <button
            class="btn btn-sort md"
            :class="{'active': userslist_filter.sort === 'role'}"
            @click="sort_change('role')"
          >
            <span>Role</span>
            <span class="icon"><svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 2l2 2 1-1-3-3-3 3 1 1 2-2zm0 8L2 8 1 9l3 3 3-3-1-1-2 2z" class="fill-grey"/></svg></span>
          </button>
        </div>
        <div class="assign">Assign</div>
        <div class="activity">Activity</div>
        <div class="debt">Debt</div>
      </div>
      <template v-if="!loading_userslist">
        <template v-if="userslist.length > 0">
          <div class="lb-bp-32">
            <div
              class="thumbnail-user color-dark"
              v-for="(item, i) in userslist"
              :key="i + 'sa'"
            >
              <div class="check">
                <label class="checkbox-entry">
                  <input
                    type="checkbox"
                    class="subscribe-input"
                    :value="item"
                    v-model="inputUsersChecked"
                  >
                  <span></span>
                </label>
              </div>
              <div class="name">
                <span v-if="item.profile">{{item.profile.fullname}}</span>
                <span v-else>-</span>
              </div>
              <div class="location">
                <span v-if="item.profile">{{item.profile.location}}</span>
                <span v-else>-</span>
              </div>
              <template v-if="type !== 'coach'">
                <div class="account">
                <span
                  class="badge-ml sm"
                  v-if="item.package"
                  :class="{'bg-green': item.package === 'Bronze', 'bg-red': item.package === 'Gold', 'bg-orang': item.package === 'Silver'}"
                >
                  {{item.package}}
                </span>
                </div>
              </template>
              <div class="role">
                <span class="badge-ml bg-grey2 sm">{{item.type}}</span>
              </div>
              <div class="assign">
                <template v-if="item.type === 'client' && item.assign && item.assign.length > 0">
                  <template v-if="item.assign.length === 1">
                    <div
                      class="assign-inner"
                      v-for="(coach, i) in item.assign"
                      :key="i + 's'"
                    >
                      <div class="image">
                        <img v-if="coach.photo" :src="coach.photo" alt="image">
                        <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
                      </div>
                      <div class="name-in text-md color-dark">{{coach.name}}</div>
                    </div>
                  </template>
                  <template v-if="item.assign.length > 1">
                    <div class="assign-wrapper">
                      <div
                        class="assign-inner tooltips-ms"
                        v-for="(coach, i) in item.assign"
                        :key="i + 'd'"
                      >
                        <div class="image">
                          <img v-if="coach.photo" :src="coach.photo" alt="image">
                          <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
                        </div>
                        <div class="tooltips"><span class="in-text">{{coach.name}}</span></div>
                      </div>
                    </div>
                  </template>
                  <!--
                <div
                  class="assign-inner"
                  v-if="item.profile"
                >
                  <div class="image">
                    <img src="@/assets/img/default-user-avatar.png" alt="image">
                  </div>
                  <div class="name-in text-md color-dark">{{item.profile.fullname}}</div>
                </div>
                <div v-else>-</div>
                -->
                </template>
                <template v-else>
                  -
                </template>
              </div>
              <div class="activity">
                <line-chart-shadow
                  :info="activity"
                  colorBorderChart="#02BB9F"
                  colorBgChart="rgba(1,1,1,0)"
                ></line-chart-shadow>
              </div>
              <div class="debt">
                <span v-if="item.debt" class="color-red">€{{item.debt}}</span>
                <span v-else>-</span>
              </div>
              <div class="info-more">
                <div class="dropdown right no-arrow">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-ghost btn-icon" @click="dropOpen">
                    <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
                  </button>
                  <ul class="dropdown-inner">
                    <li
                      v-if="item.type === 'client'"
                      @click="openInfoClients(item.id); dropClose($event)"
                    >
                      <span>Open info</span>
                    </li>
                    <li
                      v-else
                      @click="openInfoCoach(item.id); dropClose($event)"
                    >
                      <span>Open info</span>
                    </li>
                    <li
                      v-if="item.type !== 'coach'"
                    >
                      <span>Generate a report</span>
                    </li>
                    <li
                      @click="choosingCoachOpen(item.id), dropClose($event)"
                      v-if="item.type === 'client' && item.package !== 'Bronze'"
                    >
                      <span>Assign a coach</span>
                    </li>
                    <li><span class="color-red">Block</span></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div class="text-center lb-bp-32">
            <div class="pagination">
              <button
                class="btn btn-outlined prev"
                :class="{'disable': 1 >= userslist_pages.current_page}"
                @click="userslist_filter.current_page -= 1"
              >
                Prev
              </button>
              <ul>
                <li
                  class="btn btn-outlined"
                  v-if="userslist_pages.current_page !== 1"
                  @click="userslist_filter.current_page = 1"
                >
                  1
                </li>
                <li
                  v-if="userslist_pages.current_page > 2"
                >...</li>
                <li
                  class="btn btn-outlined is-active"
                >
                  {{userslist_pages.current_page}}
                </li>
                <li
                  v-if="userslist_pages.current_page < (userslist_pages.last_page - 1)"
                >...</li>
                <li
                  class="btn btn-outlined"
                  v-if="userslist_pages.current_page !== userslist_pages.last_page"
                  @click="userslist_filter.current_page = userslist_pages.last_page"
                >
                  {{userslist_pages.last_page}}
                </li>
              </ul>
              <button
                class="btn btn-outlined next"
                :class="{'disable': userslist_pages.last_page <= userslist_pages.current_page}"
                @click="userslist_filter.current_page += 1"
              >
                Next
              </button>
            </div>
          </div>
        </template>
        <div v-else>None</div>
      </template>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="chooseCoachShowModal"
      slide-animation="slide"
      view-modal-container="modal-container type-two"
      @closemodal="choosingCoachClose"
    >
      <div class="h2 color-dark lb-mb-32">Assign a coach</div>
      <div class="lb-mb-40">
        <div
          class="sl-select-wrapper-material z-index-1 search"
          :class="{'typed': chooseCoach}"
        >
          <multiselect
            placeholder=""
            label="name"
            track-by="user_id"
            v-model="chooseCoach"
            :options="optionsCoach"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            @search-change="searchCoach"
          >
            <template slot="noOptions">Enter your search keyword</template>
            <div slot="noResult">
              <div v-if="loading_coach" class="loader-wrapper p-0">
                <div class="loader-circle sm">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
              <span v-else>
                    <span v-if="one_letter_coach">Enter your search keyword</span>
                    <span v-else>Oops! No elements found.</span>
                  </span>
            </div>
          </multiselect>
          <label>Choose a coach</label>
        </div>
      </div>
      <div class="btn-wrapper right">
        <button class="btn btn-secondary" @click="choosingCoachClose">Cancel</button>
        <button
          class="btn btn-primary"
          :class="{'disable': !chooseCoach, 'loader': loading_choose_coach}"
          @click="choosingCoachSave"
        >
          Assign
        </button>
      </div>
    </pop-up>
    <pop-up
      :show-modal="clientShowModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeInfoClients"
    >
      <div class="h2 color-dark lb-mb-40">Client</div>
      <div v-if="!loading_userslist_client">
        <more-info-client
          :content="info_userslist_client"
          @update="updateInfoClient"
          @update-list="update_userslist"
          @close="closeInfoClients"
        ></more-info-client>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </pop-up>
    <pop-up
      :show-modal="coachShowModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeInfoCoach"
    >
      <div class="h2 color-dark lb-mb-40">Coach</div>
      <div v-if="!loading_userslist_coach">
        <more-info-coach
          :content="info_userslist_coach"
        ></more-info-coach>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import LineChartShadow from '@/components/LineChartShadow'
import PopUp from '@/components/PopUp'
import Multiselect from 'vue-multiselect'
import MoreInfoClient from '@/components/MoreInfoClient'
import MoreInfoCoach from '@/components/MoreInfoCoach'
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    isAll: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      clientShowModal: false,
      coachShowModal: false,
      idActiveUsers: null,
      chooseCoach: '',
      optionsCoach: [],
      loading_choose_coach: false,
      chooseCoachShowModal: false,
      activeSort: '',
      inputLocation: [],
      inputAccount: [],
      searchUsers: '',
      inputUsersChecked: [],
      activity: [
        {
          day: '05.06.2021',
          quantity: 1
        },
        {
          day: '06.06.2021',
          quantity: 4
        },
        {
          day: '07.06.2021',
          quantity: 2
        },
        {
          day: '07.06.2021',
          quantity: 5
        },
        {
          day: '07.06.2021',
          quantity: 1
        },
        {
          day: '07.06.2021',
          quantity: 2
        },
        {
          day: '07.06.2021',
          quantity: 5
        },
        {
          day: '07.06.2021',
          quantity: 4
        },
        {
          day: '07.06.2021',
          quantity: 7
        },
        {
          day: '07.06.2021',
          quantity: 5
        },
        {
          day: '07.06.2021',
          quantity: 9
        }
      ],
      userslist_filter: {
        current_page: 1,
        type: this.type,
        account: [],
        search: '',
        sort: '',
        direction: ''
      },
      loading_userslist: true,
      loading_userslist_coach: true,
      loading_userslist_client: true,
      one_letter_coach: false,
      loading_coach: false
    }
  },
  computed: {
    // userslist_filter: function () {
    //   return {
    //     current_page: 1,
    //     type: this.type,
    //     account: this.inputAccount,
    //     search: ''
    //   }
    // },
    userslist_pages: function () {
      return this.$store.getters.userslist
    },
    userslist: function () {
      return this.$store.getters.userslist.data
    },
    info_userslist_coach: function () {
      return this.$store.getters.info_userslist_coach
    },
    info_userslist_client: function () {
      return this.$store.getters.info_userslist_client
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    run_search: function () {
      this.userslist_filter.search = this.searchUsers
    },
    sort_change: function (e) {
      const a = this.userslist_filter
      if (e === a.sort) {
        a.direction = a.direction === 'asc' ? 'desc' : a.direction === 'desc' ? 'asc' : ''
      } else {
        a.sort = e
        a.direction = 'asc'
      }
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    choosingCoachOpen: function (i) {
      this.chooseCoachShowModal = true
      this.idActiveUsers = i
    },
    choosingCoachClose: function () {
      this.chooseCoachShowModal = false
      this.chooseCoach = ''
      this.idActiveUsers = null
    },
    choosingCoachSave: function () {
      const p = {
        coach_id: this.chooseCoach.user_id,
        client_id: this.idActiveUsers
      }
      this.loading_choose_coach = true
      this.$store.dispatch('changeCoachSave', p)
        .finally(() => {
          this.loading_choose_coach = false
          this.chooseCoach = ''
          this.idActiveUsers = null
          this.chooseCoachShowModal = false
          this.fetch_userslist(this.userslist_filter, false)
        })
    },
    openInfoClients: function (i) {
      this.clientShowModal = true
      this.$utils.blockScroll()
      this.loading_userslist_client = true
      this.$store.dispatch('fetchUserslistClient', i)
        .finally(() => {
          this.loading_userslist_client = false
        })
    },
    updateInfoClient: function (i) {
      this.$utils.blockScroll()
      // this.loading_userslist_client = true
      this.$store.dispatch('fetchUserslistClient', i)
        .finally(() => {
          // this.loading_userslist_client = false
        })
    },
    closeInfoClients: function () {
      this.clientShowModal = false
      this.$utils.unBlockScroll()
      this.$store.commit('cleanseUserslistClient')
    },
    openInfoCoach: function (i) {
      this.coachShowModal = true
      this.$utils.blockScroll()
      this.loading_userslist_coach = true
      this.$store.dispatch('fetchUserslistCoach', i)
        .finally(() => {
          this.loading_userslist_coach = false
        })
    },
    closeInfoCoach: function () {
      this.coachShowModal = false
      this.$utils.unBlockScroll()
      this.$store.commit('cleanseUserslistCoach')
    },
    fetch_userslist: function (e, l = true) {
      if (l) {
        this.loading_userslist = true
      }
      if (this.isNew) {
        this.$store.dispatch('fetchUserslistNew', e)
          .finally(() => {
            this.loading_userslist = false
          })
      } else if (this.isAll) {
        this.$store.dispatch('fetchUserslist', e)
          .finally(() => {
            this.loading_userslist = false
          })
      } else {
        this.$store.dispatch('fetchUserslistType', e)
          .finally(() => {
            this.loading_userslist = false
          })
      }
    },
    update_userslist: function () {
      this.fetch_userslist(this.userslist_filter, false)
    },
    searchCoach: function (s, i) {
      if (s.length === 1) {
        this.one_letter_coach = true
      } else {
        this.one_letter_coach = false
      }
      if (s.length >= 2) {
        this.loading_coach = true
        console.log(this.userSecurity)
        this.$axios({
          method: 'post',
          url: this.$root.$data.baseURL + 'admin/coachsearch',
          headers: {
            Authorization: 'Bearer ' + this.userSecurity.token,
            'X-Api-Key': this.userSecurity.key
          },
          params: {
            search: s
          }
        })
          .then((r) => {
            console.log(r)
            this.optionsCoach = r.data
            this.loading_coach = false
          })
          .catch(function (e) {
            console.log(e.response)
            this.loading_coach = false
          }.bind(this))
      } else {
        this.optionsCoach = []
        this.loading_coach = false
      }
    }
  },
  mounted () {
    this.fetch_userslist(this.userslist_filter)
  },
  watch: {
    userslist_filter: {
      handler: function (v) {
        this.fetch_userslist(v)
      },
      deep: true
    },
    chooseCoach: {
      handler: function (v) {
        console.log(v)
      },
      deep: true
    }
  },
  components: {
    LineChartShadow,
    PopUp,
    Multiselect,
    MoreInfoClient,
    MoreInfoCoach
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../assets/scss/_multiselect.scss";
@import "../assets/scss/_thumbnail.scss";
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
