import axios from 'axios'
export default {
  state: {
    userSecurity: JSON.parse(localStorage.getItem('usersecurity_ad')) || null
  },
  mutations: {
    autoLoginUser (state, payload) {
      state.userSecurity = payload
    }
  },
  actions: {
    async loginUser ({ commit, getters }, payload) {
      try {
        const v = await axios({
          method: 'get',
          url: getters.baseURL + 'apicall/init?check=' + payload,
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
        localStorage.setItem('usersecurity_ad', JSON.stringify(v.data))
        commit('autoLoginUser', v.data)
      } catch (error) {
        localStorage.removeItem('usersecurity_ad')
        commit('setError', error)
      }
    },
    async logoutUser ({ commit }) {
      localStorage.removeItem('usersecurity_ad')
      commit('logoutUser')
    }
  },
  getters: {
    userSecurity (state) {
      return state.userSecurity
    }
  }
}
