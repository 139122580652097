import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard'),
    beforeEnter: AuthGuard
  },
  {
    path: '/jobs/professions',
    name: 'Professions',
    component: () => import('@/views/jobs/Professions'),
    beforeEnter: AuthGuard
  },
  {
    path: '/jobs/vacancies',
    name: 'Vacancies',
    component: () => import('@/views/jobs/Vacancies'),
    beforeEnter: AuthGuard
  },
  {
    path: '/teaching-material',
    name: 'TeachingMaterial',
    component: () => import('@/views/TeachingMaterial'),
    beforeEnter: AuthGuard
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/users'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '/users/all',
        name: 'UsersAll',
        component: () => import('@/views/users/All'),
        beforeEnter: AuthGuard
      },
      {
        path: '/users/new',
        name: 'UsersNew',
        component: () => import('@/views/users/New'),
        beforeEnter: AuthGuard
      },
      {
        path: '/users/coaches',
        name: 'UsersCoaches',
        component: () => import('@/views/users/Coaches'),
        beforeEnter: AuthGuard
      },
      {
        path: '/users/mentors',
        name: 'UsersMentors',
        component: () => import('@/views/users/Mentors'),
        beforeEnter: AuthGuard
      },
      {
        path: '/users/clients',
        name: 'UsersClients',
        component: () => import('@/views/users/Clients'),
        beforeEnter: AuthGuard
      }
    ]
  },
  {
    path: '*',
    meta: { hideSideNav: true },
    component: () => import('@/views/PageNotFound')
  }
]
export default new VueRouter({
  routes,
  mode: 'history'
})
