<template>
  <div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeModal"
    >
      <div class="h2 color-dark lb-mb-40">Add material</div>
      <div v-if="postEdit">
        <div class="lb-mb-20">
          <div class="lb-mb-8">
            <div
              class="sl-select-wrapper-material"
              :class="{'typed': activeTypeMaterial.length > 0}"
            >
              <multiselect
                placeholder=""
                v-model="activeTypeMaterial"
                :options="optionsTypeMaterial.map(e => e.id)"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{optionsTypeMaterial.find(e => e.id === option).title}}</template>
                <template slot="option" slot-scope="props">{{optionsTypeMaterial.find(e => e.id === props.option).title}}</template>
              </multiselect>
              <label>Type</label>
            </div>
          </div>
          <div class="lb-mb-8" v-if="activeTypeMaterial === 'course'">
            <div
              class="sl-select-wrapper-material"
              :class="{'typed': postEdit.source}"
            >
              <label>Platform</label>
              <multiselect
                placeholder=""
                v-model="postEdit.source"
                :options="optionsPlatform"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
              >
              </multiselect>
            </div>
          </div>
          <div class="lb-mb-8">
            <div
              class="sl-select-wrapper-material z-index-1 search"
              :class="{'typed': postEdit.language}"
            >
              <multiselect
                placeholder=""
                label="value"
                track-by="id"
                v-model="postEdit.language"
                :options="optionsLanguage"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions">Enter your search keyword</template>
                <div slot="noResult">
                  <div v-if="loading_language" class="loader-wrapper p-0">
                    <div class="loader-circle sm">
                      <img src="@/assets/img/loader-icon.png" alt="loader">
                    </div>
                  </div>
                  <span v-else>
                    Oops! No elements found.
                </span>
                </div>
              </multiselect>
              <label>Language</label>
            </div>
          </div>
          <div class="lb-mb-8" v-if="activeTypeMaterial === 'course'">
            <div
              class="ml-select-wrapper-material z-index-1"
              :class="{'typed': postEdit.subtitles.length > 0}"
            >
              <multiselect
                tag-placeholder="Add this as new tag"
                placeholder=""
                v-model="postEdit.subtitles"
                :options="optionsLanguage.map(e => e.value)"
                :multiple="true"
              >
                <span slot="noResult">Oops! No elements found.</span>
              </multiselect>
              <label>Subtitles</label>
            </div>
          </div>
          <div class="lb-mb-8" v-if="activeTypeMaterial === 'course'">
            <div class="input-wrapper-material">
              <div
                class="input-inner"
                :class="{'typed': postEdit.certificate}"
              >
                <input
                  class="input input-material"
                  name="name"
                  type="text"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="postEdit.certificate"
                >
                <label>Certificate</label>
              </div>
            </div>
          </div>
          <div class="editor-wrapper">
            <editor-content :editor="editor" />
          </div>
          <div class="nav-editor">
            <button class="btn" @click="editor.chain().focus().toggleBulletList().run()">Add list</button>
          </div>
        </div>
        <div class="lb-mb-20">
          <div class="h4 lb-mb-2">General information</div>
          <div class="text-md color-grey lb-mb-15">Fill in the required fields to describe the material</div>
          <div class="form-group md">
            <div class="input-wrapper-material">
              <div
                class="input-inner"
                :class="{'typed': postEdit.title}"
              >
                <input
                  class="input input-material"
                  name="name"
                  type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.postEdit.title.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="postEdit.title"
                  :class="{'error': $v.postEdit.title.$error}"
                >
                <label>Add title</label>
              </div>
              <div v-if="$v.postEdit.title.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div
                class="input-inner"
                :class="{'typed': postEdit.author}"
              >
                <input
                  class="input input-material"
                  name="name"
                  type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.postEdit.author.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="postEdit.author"
                  :class="{'error': $v.postEdit.author.$error}"
                >
                <label>Author</label>
              </div>
              <div v-if="$v.postEdit.author.$error" class="input-error">Error</div>
            </div>
            <div
              class="ml-select-wrapper-material"
              :class="{'typed': postEdit.tags.length > 0}"
            >
              <multiselect
                tag-placeholder="Add this as new tag"
                placeholder=""
                label="skill"
                track-by="id"
                v-model="postEdit.tags"
                :options="optionsTopic"
                :multiple="true"
                @search-change="searchTopic"
              >
                <template slot="noOptions">Enter your search keyword</template>
                <div slot="noResult">
                  <div v-if="loading_topic" class="loader-wrapper p-0">
                    <div class="loader-circle sm">
                      <img src="@/assets/img/loader-icon.png" alt="loader">
                    </div>
                  </div>
                  <span v-else>
                    <span v-if="one_letter_topic">Enter your search keyword</span>
                    <span v-else>Oops! No elements found.</span>
                  </span>
                </div>
              </multiselect>
              <label>Tags</label>
            </div>
            <div class="input-wrapper-material textarea">
              <div
                class="input-inner"
                :class="{'typed': postEdit.description}"
              >
                <textarea
                  class="input md input-material typed"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="postEdit.description"
                ></textarea>
                <label>Description</label>
              </div>
            </div>
            <div class="row row-8">
              <div class="col-lg-4">
                <div class="input-wrapper-material">
                  <div
                    class="input-inner"
                    :class="{'typed': postEdit.level}"
                  >
                    <input
                      class="input input-material"
                      name="name"
                      type="text"
                      @focus="focusInput"
                      @blur="blurInput($event); $v.postEdit.level.$touch()"
                      @input="typedInput"
                      @change="typedInput"
                      v-model="postEdit.level"
                      :class="{'error': $v.postEdit.level.$error}"
                    >
                    <label>Level</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="input-wrapper-material">
                  <div
                    class="input-inner"
                    :class="{'typed': postEdit.rating}"
                  >
                    <input
                      class="input input-material"
                      name="name"
                      type="text"
                      @focus="focusInput"
                      @blur="blurInput($event); $v.postEdit.rating.$touch()"
                      @input="typedInput"
                      @change="typedInput"
                      v-model="postEdit.rating"
                      :class="{'error': $v.postEdit.rating.$error}"
                    >
                    <label>Rating</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="input-wrapper-material">
                  <div
                    class="input-inner"
                    :class="{'typed': postEdit.reviwes}"
                  >
                    <input
                      class="input input-material"
                      name="name"
                      type="text"
                      @focus="focusInput"
                      @blur="blurInput($event); $v.postEdit.reviwes.$touch()"
                      @input="typedInput"
                      @change="typedInput"
                      v-model="postEdit.reviwes"
                      :class="{'error': $v.postEdit.reviwes.$error}"
                    >
                    <label>Reviews</label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="image-upload lb-mb-15 drop-block"
              @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave"
              @drop.prevent="drop($event)"
            >
              <div v-if="!imageUpload" class="image-upload-inner">
                <span class="icon"><img src="@/assets/img/icons/icon-picture-mc.svg" alt="icon"></span>
                <div class="text-in text-sm">Drag the image or <span class="btn-link" @click="triggerUpload">upload</span></div>
                <input
                  style="display: none"
                  @change="changeFile($event)"
                  ref="fileInput"
                  type="file"
                  accept="image/*"
                  name="imgUpload123"
                >
              </div>
              <img v-if="imageUpload" :src="imageUpload" class="up-img" alt="image">
            </div>
          </div>
        </div>
        <div class="lb-mb-40">
          <div class="h4 lb-mb-2">Link</div>
          <div class="text-md color-grey lb-mb-15">Fill in the required fields</div>
          <div class="input-wrapper-material">
            <div
              class="input-inner"
              :class="{'typed': postEdit.link}"
            >
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.postEdit.link.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="postEdit.link"
                :class="{'error': $v.postEdit.link.$error}"
              >
              <label>Provide a link to the material</label>
            </div>
            <div v-if="$v.postEdit.link.$error" class="input-error">Error</div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary"
            :class="{'disable': $v.$invalid, 'loader': localLoading}"
            @click="sendPost"
          >
            Add material
          </button>
          <button
            class="btn btn-secondary"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import { required, url, numeric, minValue, maxValue, decimal } from 'vuelidate/lib/validators'
import PopUp from '@/components/PopUp'
import Multiselect from 'vue-multiselect'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postEdit: '',
      localLoading: false,
      material_model: {
        title: '',
        type: '',
        source: '',
        author: '',
        description: '',
        link: '',
        tags: '',
        imageUpload: '',
        level: null,
        rating: null,
        reviwes: null,
        topic2: ['1', '2'],
        date: '2021-04-14',
        subtitles: [],
        certificate: '',
        requirements: ''
      },
      optionsTypeMaterial: [
        {
          id: 'course',
          title: 'Courses'
        },
        {
          id: 'book',
          title: 'Books'
        },
        {
          id: 'article',
          title: 'Articles'
        }
      ],
      optionsPlatform: ['Udemy', 'Coursera'],
      activeTypeMaterial: 'course',
      imageUpload: '',
      optionsTopic: [],
      loading_topic: false,
      one_letter_topic: false,
      optionsLanguage: [],
      one_letter_language: false,
      loading_language: false,
      editor: null
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('closemodal')
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    dragOver: function (e) {
      e.target.classList.add('is-hover')
    },
    dragLeave: function (e) {
      e.target.classList.remove('is-hover')
    },
    drop: function (e) {
      const file = e.dataTransfer.files[0]
      const formData = new FormData()
      this.postEdit.imageUpload = formData
      formData.set('photo', file)
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUpload = reader.result
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('photo', file)
      this.postEdit.imageUpload = formData
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUpload = reader.result
      }
      reader.readAsDataURL(file)
    },
    searchTopic: function (s, i) {
      if (s.length === 1) {
        this.one_letter_topic = true
      } else {
        this.one_letter_topic = false
      }
      if (s.length >= 2) {
        this.loading_topic = true
        this.$axios({
          method: 'post',
          url: this.$root.$data.baseURL + 'client/skillsearch',
          params: {
            search: s
          }
        })
          .then((r) => {
            this.optionsTopic = r.data
            this.loading_topic = false
          })
          .catch(function (e) {
            console.log(e)
            this.loading_topic = false
          })
      } else {
        this.optionsTopic = []
        this.loading_topic = false
      }
    },
    sendPost: function () {
      this.localLoading = true
      this.postEdit.type = this.activeTypeMaterial
      this.postEdit.requirements = this.editor.getHTML()
      this.$store.dispatch('sendMaterials', this.postEdit)
        .finally(() => {
          this.$emit('end-save')
          this.localLoading = false
          this.postEdit = ''
        })
    }
  },
  watch: {
    showModal: function (v) {
      if (v) {
        this.postEdit = JSON.parse(JSON.stringify(this.material_model))
        this.$v.$reset()
      }
    },
    tegs_search: function (v) {
      if (v.length >= 2) {
        this.tegs_loading = true
        this.$axios({
          method: 'post',
          url: this.$root.$data.baseURL + 'admin/tagsearch',
          params: {
            search: v
          }
        })
          .then((r) => {
            this.tegs_other = [...r.data]
            this.tegs_loading = false
          })
          .catch(function (e) {
            this.tegs_loading = false
            console.log(e)
          })
      } else {
        this.tegs_other = []
        this.tegs_loading = false
      }
    }
  },
  mounted () {
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'getlangs'
    })
      .then((r) => {
        this.optionsLanguage = r.data
        this.loading_language = false
      })
      .catch(function (e) {
        this.loading_language = false
      })
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Placeholder
      ],
      content: `
      `
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  validations: {
    postEdit: {
      title: {
        required
      },
      author: {
        required
      },
      tags: {
        required
      },
      link: {
        required,
        url
      },
      level: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(5)
      },
      reviwes: {
        required,
        numeric,
        minValue: minValue(0)
      },
      rating: {
        required,
        decimal,
        minValue: minValue(0),
        maxValue: maxValue(5)
      }
    }
  },
  components: {
    PopUp,
    Multiselect,
    EditorContent
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../assets/scss/_multiselect.scss";
.nav-editor{
  .btn{
    padding: 6px 12px;
    color: #5e4bf1;
    font-size: 14px;
    font-weight: 500;
    transition: all .24s ease-out;
    cursor: pointer;
    &:first-child{
      padding-left: 0!important;
    }
  }
}
.nav-editor .btn:hover{
  opacity: 0.8;
}
.editor-wrapper{
  display: block;
  margin-bottom: 8px;
  border: 1px solid rgba(0,0,0,.08);
  border-radius: 6px;
  padding: 10px 12px;
  .ProseMirror {
    font-size: 14px;
    min-height: 50px;
    outline: none;
    color: #161616;
    border-radius: 6px;
    > * + * {
      margin-top: 15px;
    }
    ul{
      list-style: disc;
    }
    ol{
      list-style: decimal;
    }
    ul,
    ol {
      padding: 0 1rem;
    }
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    content: 'Requirements';
    float: left;
    font-size: 14px;
    color: #767676;
    pointer-events: none;
    height: 0;
  }
}
.image-upload{
  position: relative;
  padding-bottom: 36%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
  &-inner{
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      img{
        max-width: 100%;
      }
    }
  }
  .up-img{
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.drop-block{
  transition: all .34s ease-out;
  &.is-hover{
    background-color: rgba(0, 0, 0, 0.12);
  }
}
</style>
