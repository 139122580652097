<template>
  <div>
    <div class="lb-mb-8">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="h3">Professions statistics</div>
        </div>
        <div class="col-auto">
          <div class="btn-wrapper sm">
            <button
              class="btn btn-outlined dark-gr"
              :class="{'is-active': option === 'current'}"
              @click="option = 'current'"
            >
              Work now
            </button>
            <button
              class="btn btn-outlined dark-gr"
              :class="{'is-active': option === 'selected'}"
              @click="option = 'selected'"
            >
              Selected professions
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-layout">
      <template v-if="!loading_prof">
        <div class="row row-50 align-items-center">
          <div class="col-auto">
            <div
              v-if="professionsStatisticsGet"
              class="doughnut-chart-wrapper"
            >
              <div class="doughnut-chart-text">
                <div class="color-dark title">Total professions</div>
                <div class="h1 number">{{professionsStatisticsDT.total}}</div>
              </div>
              <doughnut-chart
                :chart-data="professionsStatisticsGet"
                :options="optionsDoughnut"
              >
              </doughnut-chart>
            </div>
          </div>
          <div class="col-lg-6">
            <ul
              class="lab-grafic-list text-md"
              v-if="professionsStatisticsDT"
            >
              <li
                v-for="(item, i) in professionsStatisticsDT.list"
                :key="i + 'ct'"
              >
                  <span class="lab-grafic-lf">
                    <span :style="{'background-color': item.color}" class="circle"></span><span class="text-in color-dark">{{item.title}}</span><span>{{item.counts}}</span>
                  </span>
                <span class="lab-grafic-rg color-dark">{{item.percentage}}%</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DoughnutChart from '@/components/DoughnutChart'
export default {
  data () {
    return {
      optionsDoughnut: {
        responsive: true,
        legend: {
          display: false
        },
        cutoutPercentage: 84,
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        plugins: {
          title: {
            display: true,
            text: 'Title'
          }
        }
      },
      option: 'current',
      loading_prof: false
    }
  },
  computed: {
    professionsStatisticsDT: function () {
      if (this.professions) {
        let p
        let n = 1
        if (this.option === 'current') {
          p = this.professions.current
        } else if (this.option === 'selected') {
          p = this.professions.selected
        }
        const c = {
          list: [],
          total: p.total
        }
        for (const i in p.list) {
          c.list.push({
            title: i,
            counts: p.list[i],
            percentage: (+p.list[i] * 100 / +p.total).toFixed(1),
            color: this.get_color(+n)
          })
          if (n < 8) {
            n++
          } else {
            n = 1
          }
        }
        return c
      } else {
        return ''
      }
    },
    professionsStatisticsGet: function () {
      if (this.professionsStatisticsDT) {
        const l = []
        const d = []
        const c = []
        this.professionsStatisticsDT.list.forEach((e) => {
          l.push(e.title)
          d.push(e.percentage)
          c.push(e.color)
        })
        return {
          labels: l,
          datasets: [
            {
              label: 'd',
              data: d,
              backgroundColor: c
            }
          ]
        }
      } else {
        return ''
      }
    },
    professions: function () {
      return this.$store.getters.professions_statistics
    }
  },
  methods: {
    get_color (e) {
      switch (e) {
        case 1: return '#6685FF'
        case 2: return '#AD66FF'
        case 3: return '#FF66EB'
        case 4: return '#FF6666'
        case 5: return '#FFA366'
        case 6: return '#FFD666'
        case 7: return '#8FFF66'
        case 8: return '#66DBFF'
        case 9: return '#66B6FF'
        default: return '#FFA366'
      }
    },
    fetchContent: function () {
      if (this.professions === '') {
        this.loading_prof = true
      }
      this.$store.dispatch('fetchProfessionsStatistics', this.option_period)
        .finally(() => {
          this.loading_prof = false
        })
    }
  },
  mounted () {
    this.fetchContent()
  },
  components: {
    DoughnutChart
  }
}
</script>
