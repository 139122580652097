<template>
  <div>
    <transition name="fade" appear>
      <div class="modal-overlay" v-if="showModal" @click="$emit('closemodal')"></div>
    </transition>
    <transition :name="slideAnimation" appear>
      <div :class="viewModalContainer" v-if="showModal">
        <button class="btn btn-close"  @click="$emit('closemodal')"></button>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    slideAnimation: {
      type: String,
      default: 'slide'
    },
    viewModalContainer: {
      type: String,
      default: 'modal-container'
    }
  },
  data () {
    return {}
  }
}
</script>
