<template>
  <div>
    <div class="lb-mb-8">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="h3">General statistics</div>
        </div>
        <div class="col-auto">
          <div class="dropdown drop-select min-a">
            <span class="dropdown-overlay" @click="dropClose"></span>
            <button class="btn btn-outlined" @click="dropOpen">Last Year</button>
            <ul class="dropdown-inner">
              <li @click="dropChangeLabel('All time', $event); option_period = 'alltime'" :class="{'is-active': option_period === 'alltime'}">All time</li>
              <li @click="dropChangeLabel('Last Year', $event); option_period = 'year'" :class="{'is-active': option_period === 'year'}">Last Year</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="block-layout">
      <div class="lab-country-wrapper">
        <div class="item">
          <div class="color-grey text-md title">Total users</div>
          <div v-if="!loading_counts" class="h2 number">{{user_counts.total}}</div>
          <div v-else class="h2 number">--</div>
        </div>
        <div class="item">
          <div class="color-grey text-md title">Clients</div>
          <div v-if="!loading_counts" class="h2 number">{{user_counts.clients}}</div>
          <div v-else class="h2 number">--</div>
        </div>
        <div class="item">
          <div class="color-grey text-md title">Coaches</div>
          <div v-if="!loading_counts" class="h2 number">{{user_counts.coaches}}</div>
          <div v-else class="h2 number">--</div>
        </div>
        <div class="item">
          <div class="color-grey text-md title">Mentors</div>
          <div v-if="!loading_counts" class="h2 number">{{user_counts.mentors}}</div>
          <div v-else class="h2 number">--</div>
        </div>
        <div class="item">
          <div class="color-grey text-md title">Online users</div>
          <div v-if="!loading_counts" class="h2 number">{{user_counts.online}}</div>
          <div v-else class="h2 number">--</div>
        </div>
      </div>
      <div class="lab-country-chart">
        <template v-if="!loading_counts">
          <template v-if="user_counts">
            <bar-chart
              :chart-data="generalStatisticsGet"
              :options="generalStatisticsOP"
            ></bar-chart>
          </template>
        </template>
        <div v-else class="loader-wrapper">
          <div class="loader-circle">
            <img src="@/assets/img/loader-icon.png" alt="loader">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BarChart from '@/components/BarChart'
export default {
  data () {
    return {
      generalStatisticsOP: {
        responsive: true,
        maintainAspectRatio: false,
        barRoundness: 0.6,
        // animation: false,
        legend: {
          display: false
        },
        plugins: {
          title: {
            display: false
          }
        },
        scales: {
          xAxes: [{
            barPercentage: 0.8,
            categoryPercentage: 1,
            gridLines: {
              display: false,
              tickMarkLength: 12
            },
            ticks: {
              fontSize: 14
            }
          }],
          yAxes: [{
            minBarLength: 5,
            gridLines: {
              display: false,
              tickMarkLength: 0
            },
            display: true,
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0
            }
          }]
        }
      },
      option_period: 'alltime',
      loading_counts: false
    }
  },
  computed: {
    generalStatisticsGet: function () {
      const l = []
      const d = []
      if (this.user_counts !== '') {
        for (const i in this.user_counts.bymonth) {
          l.push(this.get_month(+i))
          d.push(this.user_counts.bymonth[i])
        }
        return {
          labels: l,
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#DDE4FF',
              borderRadius: 120,
              borderSkipped: false,
              data: d
            }
          ]
        }
      } else {
        return undefined
      }
    },
    user_counts: function () {
      return this.$store.getters.user_counts
    }
  },
  methods: {
    dropChangeLabel (c, e) {
      const $wrap = e.target.closest('.dropdown')
      const t = e.target.innerHTML
      $wrap.classList.remove('is-active')
      $wrap.querySelector('.btn').innerText = t
      this.activeSort = c
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    fetchContent: function () {
      if (this.user_counts === '') {
        this.loading_counts = true
      }
      this.$store.dispatch('fetchUserCounts', this.option_period)
        .finally(() => {
          this.loading_counts = false
        })
    },
    get_month (e) {
      switch (e) {
        case 1: return 'Jan'
        case 2: return 'Fab'
        case 3: return 'Mar'
        case 4: return 'Apr'
        case 5: return 'May'
        case 6: return 'Jun'
        case 7: return 'Jul'
        case 8: return 'Aug'
        case 9: return 'Sep'
        case 10: return 'Oct'
        case 11: return 'Nov'
        case 12: return 'Dev'
        default: return 'none'
      }
    }
  },
  mounted () {
    this.fetchContent()
  },
  watch: {
    option_period: function (v) {
      this.fetchContent()
    }
  },
  components: {
    BarChart
  }
}
</script>
