<template>
  <div class="lab-chart-block-ln">
    <div class="info">
      <div class="color-grey text-md title">{{title}}</div>
      <div
        class="h2 number"
        :class="colorNumber"
      >
        <slot name="number"></slot>
      </div>
    </div>
    <div class="chart" :style="{'max-width': widthChart + 'px'}">
      <line-chart
        :data="dataChart"
        :options="optionsChart"
      ></line-chart>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/LineChart'
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    colorNumber: {
      type: String,
      default: 'dark'
    },
    info: {
      type: Array,
      default: () => {
        return []
      }
    },
    colorBorderChart: {
      type: String,
      default: '#161616'
    },
    colorBgChart: {
      type: String,
      default: 'rgba(0, 0, 0, 0.08)'
    }
  },
  data () {
    return {
      optionsChart: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        },
        plugins: {
          title: {
            display: false
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              tickMarkLength: 0
            },
            ticks: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0
            }
          }]
        }
      }
    }
  },
  computed: {
    widthChart: function () {
      return this.info.length * 24
    },
    dataChart: function () {
      const l = []
      const d = []
      this.info.forEach((e, i) => {
        l.push(e.day)
        d.push(e.quantity)
      })
      return {
        labels: l,
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBorderWidth: 0,
            borderSkipped: false,
            lineTension: 0,
            borderWidth: 2,
            backgroundColor: this.colorBgChart,
            borderColor: this.colorBorderChart,
            data: d
          }
        ]
      }
    }
  },
  methods: {},
  components: {
    LineChart
  }
}
</script>

<style scoped lang="scss">
.lab-chart-block-ln{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 16px;
  .title{
    margin-bottom: 2px;
  }
  .number{
    &.dark{
      color: #161616;
    }
    &.green{
      color: #02BB9F;
    }
  }
  .chart{
    max-width: 250px;
    position: relative;
    height: 50px;
    > div{
      position: relative;
      height: 50px;
    }
  }
}
</style>
