<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Teaching material</div>
      <div class="header-rg">
        <button
          class="btn btn-primary"
          @click="open_add_material"
        >
          Add material
        </button>
      </div>
    </header>
    <div>
      <ul class="tabs-nav lb-mb-15">
        <li @click="material_filter.type = 'course'; change_type('course')" :class="{'is-active': material_filter.type === 'course'}"><span>Courses</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('course')}}</span></li>
        <li @click="material_filter.type = 'book'; change_type('book')" :class="{'is-active': material_filter.type === 'book'}"><span>Books</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('book')}}</span></li>
        <li @click="material_filter.type = 'article'; change_type('article')" :class="{'is-active': material_filter.type === 'article'}"><span>Articles</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('article')}}</span></li>
      </ul>
      <div class="lb-mb-15">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <div class="row row-8 align-items-center">
              <div class="col-auto">
                Filter
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Rating</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" value="1" class="subscribe-input" v-model="material_filter.rating"><span>1 Star</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="2" class="subscribe-input" v-model="material_filter.rating"><span>2 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="3" class="subscribe-input" v-model="material_filter.rating"><span>3 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="4" class="subscribe-input" v-model="material_filter.rating"><span>4 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="5" class="subscribe-input" v-model="material_filter.rating"><span>5 Stars</span></label></li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Topic</button>
                  <div class="dropdown-inner">
                    <div class="input-wrapper-icon wh-incon lb-mb-8">
                      <input
                        placeholder="Search"
                        class="input sm"
                        type="text"
                        v-model="tegs_search"
                      >
                    </div>
                    <div v-if="tegs_loading" class="loader-wrapper p-0">
                      <div class="loader-circle sm">
                        <img src="@/assets/img/loader-icon.png" alt="loader">
                      </div>
                    </div>
                    <ul class="in_mx_scroll">
                      <li
                        v-for="(item, i) in tegs_filter"
                        :key="i + '39'"
                      >
                        <label class="checkbox-entry"><input type="checkbox" :value="item.id" class="subscribe-input" v-model="material_filter.tags"><span>{{item.name_v2}}</span></label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Level</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" :value="1" class="subscribe-input" v-model="material_filter.level"><span>1</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="2" class="subscribe-input" v-model="material_filter.level"><span>2</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="3" class="subscribe-input" v-model="material_filter.level"><span>3</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="4" class="subscribe-input" v-model="material_filter.level"><span>4</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="5" class="subscribe-input" v-model="material_filter.level"><span>5</span></label></li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Platform</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" value="Coursera" class="subscribe-input" v-model="material_filter.platform"><span>Coursera</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="Udemy" class="subscribe-input" v-model="material_filter.platform"><span>Udemy</span></label></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row row-8 align-items-center">
              <div class="col-auto">Sort by</div>
              <div class="col-auto">
                <div class="dropdown drop-select min-a">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">{{material_sort.find(e => e.key === material_filter.order_sort).title}}</button>
                  <ul class="dropdown-inner">
                    <li
                      v-for="(item, i) in material_sort"
                      :key="i + 'k'"
                      :class="{ 'is-active': material_filter.order_sort === item.key }"
                      @click="material_filter.order_sort = item.key; dropClose($event)"
                    >
                      {{item.title}}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="input-wrapper-icon clean">
                  <span class="icon"><img src="@/assets/img/icons/icon-search-grey.svg" alt="icon-search"></span>
                  <button
                    class="icon-clean"
                    v-if="search_input.length"
                    @click="search_input = ''; search_mt()"
                  >
                    <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
                  </button>
                  <input
                    class="input sm"
                    placeholder="Search"
                    type="text"
                    v-model="search_input"
                    @keypress.enter="search_mt()"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!material_loading">
        <div v-if="materials.length > 0">
          <teaching-material
            :posts="materials"
            @click-card="open_edit_materialt"
          ></teaching-material>
          <div class="text-center lb-bp-32">
            <div class="pagination">
              <button
                class="btn btn-outlined prev"
                :class="{'disable': 1 >= materials_pages.current_page}"
                @click="material_filter.current_page -= 1"
              >
                Prev
              </button>
              <ul>
                <li
                  class="btn btn-outlined"
                  v-if="materials_pages.current_page !== 1"
                  @click="material_filter.current_page = 1"
                >
                  1
                </li>
                <li
                  v-if="materials_pages.current_page > 2"
                >...</li>
                <li
                  class="btn btn-outlined is-active"
                >
                  {{materials_pages.current_page}}
                </li>
                <li
                  v-if="materials_pages.current_page < (materials_pages.last_page - 1)"
                >...</li>
                <li
                  class="btn btn-outlined"
                  v-if="materials_pages.current_page !== materials_pages.last_page"
                  @click="material_filter.current_page = materials_pages.last_page"
                >
                  {{materials_pages.last_page}}
                </li>
              </ul>
              <button
                class="btn btn-outlined next"
                :class="{'disable': materials_pages.last_page <= materials_pages.current_page}"
                @click="material_filter.current_page += 1"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div v-else>None</div>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </div>
    <add-material
      ref="material"
      :show-modal="is_show_add"
      @closemodal="close_add_material"
      @end-save="save_add_material"
    ></add-material>
    <edit-material
      :show-modal="is_show_edit"
      :post-id="edit_post_id"
      @closemodal="close_edit_material"
      @end-save="save_edit_material"
    >
    </edit-material>
  </div>
</template>

<script>
import TeachingMaterial from '@/components/TeachingMaterial'
import AddMaterial from '@/components/AddMaterial'
import EditMaterial from '@/components/EditMaterial'
export default {
  data () {
    return {
      is_show_add: false,
      is_show_edit: false,
      edit_post_id: null,
      search_input: '',
      activePlatform: '',
      material_filter: {
        type: 'course',
        platform: [],
        level: [],
        rating: [],
        tags: [],
        order_sort: 'reviwes',
        search: '',
        current_page: 1
      },
      material_sort: [
        {
          key: 'reviwes',
          title: 'Most popular'
        },
        {
          key: 'new',
          title: 'New'
        },
        {
          key: 'rating',
          title: 'Rating'
        },
        {
          key: 'title',
          title: 'Name'
        }
      ],
      material_loading: true,
      tegs_default: [],
      tegs_other: [],
      tegs_search: '',
      tegs_loading: false,
      quantity_loading: true
    }
  },
  computed: {
    tegs_filter: function () {
      const d = [...this.tegs_default, ...this.tegs_other]
      return d.filter(v => {
        if (this.tegs_search === '') {
          return true
        } else {
          return v.name_v2.toLowerCase().includes(this.tegs_search.toLowerCase())
        }
      })
    },
    materials_pages: function () {
      return this.$store.getters.materials
    },
    materials: function () {
      return this.$store.getters.materials.data
    },
    materials_quantity: function () {
      return this.$store.getters.materials_quantity
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    change_type: function (e) {
      this.$refs.material.$data.activeTypeMaterial = e
    },
    open_add_material: function () {
      this.is_show_add = true
      this.$utils.blockScroll()
    },
    close_add_material: function () {
      this.is_show_add = false
      this.$utils.unBlockScroll()
    },
    save_add_material: function () {
      this.is_show_add = false
      this.$utils.unBlockScroll()
      this.fetch_materials(this.material_filter)
    },
    open_edit_materialt: function ({ id }) {
      this.edit_post_id = id
      this.is_show_edit = true
      this.$utils.blockScroll()
    },
    close_edit_material: function () {
      this.is_show_edit = false
      this.$utils.unBlockScroll()
    },
    save_edit_material: function () {
      this.is_show_edit = false
      this.$utils.unBlockScroll()
      this.fetch_materials(this.material_filter)
    },
    ob_get_key: function (a) {
      const v = JSON.parse(JSON.stringify(this.materials_quantity))
      const c = v.find(e => Object.keys(e)[0] === a)
      return Object.entries(c)[0][1]
    },

    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    search_mt: function () {
      this.material_filter.search = this.search_input
    },
    fetch_materials: function (e) {
      this.material_loading = true
      this.$store.dispatch('fetchMaterials', e)
        .finally(() => {
          if (this.material_filter.current_page !== 1 && this.materials.length < 1) {
            this.material_filter.current_page = 1
            this.$store.dispatch('fetchMaterials', this.material_filter)
              .finally(() => {
                this.material_loading = false
              })
          } else {
            this.material_loading = false
          }
        })
    },
    fetch_tegs: function () {
      this.tegs_loading = true
      this.$axios({
        method: 'get',
        url: this.$root.$data.baseURL + 'admin/getmaterialtags'
      })
        .then((r) => {
          this.tegs_default = [...r.data]
          this.tegs_loading = false
        })
        .catch(function (e) {
          this.tegs_loading = false
          console.log(e)
        })
    }
  },
  watch: {
    material_filter: {
      handler: function (v) {
        this.fetch_materials(v)
      },
      deep: true
    },
    tegs_search: function (v) {
      if (v.length >= 2) {
        this.tegs_loading = true
        this.$axios({
          method: 'post',
          url: this.$root.$data.baseURL + 'client/tagsearch',
          params: {
            search: v
          }
        })
          .then((r) => {
            this.tegs_other = [...r.data]
            this.tegs_loading = false
          })
          .catch(function (e) {
            this.tegs_loading = false
            console.log(e)
          })
      } else {
        this.tegs_other = []
        this.tegs_loading = false
      }
    }
  },
  mounted () {
    this.fetch_tegs()
    this.fetch_materials(this.material_filter)
    this.$store.dispatch('quantityMaterials')
      .finally(() => {
        this.quantity_loading = false
      })
  },
  components: {
    TeachingMaterial,
    AddMaterial,
    EditMaterial
  }
}
</script>
<style lang="scss">
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
