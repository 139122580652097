import Vue from 'vue'
import Vuex from 'vuex'
import Shared from './shared'
import User from './user'
import Materials from './materials'
import Professions from './professions'
import Dashboard from './dashboard/index'
import Userslist from './userslist'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'https://api.competenzlab.io/',
    loginURL: 'https://competenzlab.io/login'
  },
  getters: {
    baseURL (state, getters, rootState) {
      return rootState.baseURL
    },
    loginURL (state, getters, rootState) {
      return rootState.loginURL
    }
  },
  modules: {
    Shared,
    User,
    Materials,
    Professions,
    Dashboard,
    Userslist
  }
})
