<template>
  <div>
    <users-list
      :is-all="true"
    ></users-list>
  </div>
</template>

<script>
import UsersList from '@/components/UsersList'
export default {
  components: {
    UsersList
  }
}
</script>
