<template>
  <div class="line-chart-shadow">
    <line-chart
      :data="dataChart"
      :options="optionsChart"
    ></line-chart>
  </div>
</template>
<script>
import LineChart from '@/components/LineChart'
export default {
  props: {
    info: {
      type: Array,
      default: () => {
        return []
      }
    },
    colorBorderChart: {
      type: String,
      default: '#161616'
    },
    colorBgChart: {
      type: String,
      default: 'rgba(0, 0, 0, 0.08)'
    }
  },
  data () {
    return {
      optionsChart: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        },
        plugins: {
          title: {
            display: false
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              tickMarkLength: 0
            },
            ticks: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0
            }
          }]
        }
      }
    }
  },
  computed: {
    widthChart: function () {
      return this.info.length * 24
    },
    dataChart: function () {
      const l = []
      const d = []
      this.info.forEach((e, i) => {
        l.push(e.day)
        d.push(e.quantity)
      })
      return {
        labels: l,
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBorderWidth: 0,
            borderSkipped: false,
            lineTension: 0,
            borderWidth: 2,
            backgroundColor: this.colorBgChart,
            borderColor: this.colorBorderChart,
            data: d
          }
        ]
      }
    }
  },
  methods: {},
  components: {
    LineChart
  }
}
</script>

<style scoped lang="scss">
.line-chart-shadow{
  max-width: 180px;
  position: relative;
  height: 40px;
  position: relative;
  overflow: hidden;
  &:after{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    box-shadow: 0px 0px 38px 40px rgb(255 255 255 / 90%);
    z-index: 2;
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 38px 40px rgb(255 255 255 / 90%);
    z-index: 2;
  }
  > div{
    position: relative;
    height: 40px;
  }
}
</style>
