import axios from 'axios'

export default {
  state: {
    materials: '',
    materials_quantity: '',
    material_one: ''
  },
  mutations: {
    updateMaterials (state, payload) {
      state.materials = payload
    },
    updateQuantityMaterials (state, payload) {
      state.materials_quantity = payload
    },
    updateMaterialOne (state, payload) {
      state.material_one = payload
    }
  },
  actions: {
    async fetchMaterials ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getmateriallist',
          params: {
            type: payload.type,
            platform: payload.platform,
            level: payload.level,
            tags: payload.tags,
            page: payload.current_page,
            order: payload.order_sort,
            search: payload.search
          }
        })
        commit('updateMaterials', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async sendMaterials ({ commit, getters }, payload) {
      console.log(payload)
      commit('clearError')
      try {
        let s = ''
        if (payload.imageUpload) {
          s = await axios.post(getters.baseURL + 'apicall/admin/storematerialphoto', payload.imageUpload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + getters.userSecurity.token,
              'X-Api-Key': getters.userSecurity.key
            }
          })
        }
        const d = {
          id: payload.id ? payload.id : 0,
          title: payload.title,
          type: payload.type,
          source: payload.type === 'course' ? payload.source : '',
          author: payload.author,
          description: payload.description,
          image: s ? s.data.path : payload.image ? payload.image : '',
          link: payload.link,
          tags: payload.tags.map(e => e.id),
          level: +payload.level,
          rating: payload.rating,
          reviwes: +payload.reviwes,
          language: payload.language.id ? payload.language.id : payload.language ? payload.language : '',
          subtitles: payload.subtitles,
          certificate: payload.certificate,
          requirements: payload.requirements
        }
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/savematerial',
          params: {
            data: JSON.stringify(d)
          }
        })
        // commit('updateBasicprofile', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async quantityMaterials  ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getcounts'
        })
        commit('updateQuantityMaterials', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchMaterialOne ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getmaterial',
          params: {
            id: payload
          }
        })
        commit('updateMaterialOne', x.data)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    materials (state) {
      return state.materials
    },
    materials_quantity (state) {
      return state.materials_quantity
    },
    material_one (state) {
      return state.material_one
    }
  }
}
