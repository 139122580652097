<template>
  <div v-if="content">
    <div class="more-info-edit-head">
      <div class="text-in">
        <div class="h3 lb-mb-2">About person</div>
        <div class="color-grey lb-mb-15">Basic information about {{content.profile.fullname}}</div>
      </div>
      <div class="btn-wrapper">
        <button
          class="btn btn-primary"
          @click="showConfirm = true"
          :class="{'disable': $v.profileEdit.fullname.$error}"
        >
          Save changes
        </button>
        <button
          class="btn btn-secondary"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </div>
    </div>
    <div v-if="profileEdit">
      <div class="form-group md">
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.fullname}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event); $v.profileEdit.fullname.$touch()"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.fullname"
              :class="{'error': $v.profileEdit.fullname.$error}"
            >
            <label>Name</label>
          </div>
          <div v-if="$v.profileEdit.fullname.$error" class="input-error">Error</div>
        </div>
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.email}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event)"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.email"
            >
            <label>Email</label>
          </div>
        </div>
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.phone}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event)"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.phone"
            >
            <label>Phone number</label>
          </div>
        </div>
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.location}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event)"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.location"
            >
            <label>Location</label>
          </div>
        </div>
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.source}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event)"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.source"
            >
            <label>Source</label>
          </div>
        </div>
        <div class="input-wrapper-material">
          <div
            class="input-inner"
            :class="{'typed': profileEdit.channel}"
          >
            <input
              class="input input-material"
              name="name"
              type="text"
              @focus="focusInput"
              @blur="blurInput($event)"
              @input="typedInput"
              @change="typedInput"
              v-model="profileEdit.channel"
            >
            <label>Сhannel</label>
          </div>
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="showConfirm"
      view-modal-container="modal-container"
      @closemodal="closeConfirm"
    >
      <div class="h2 lb-mb-20">Please confirm edit with<br> your password</div>
      <div class="input-wrapper-material lb-mb-32">
        <div
          class="input-inner"
          :class="{'typed': profileEdit.password}"
        >
          <input
            class="input input-material"
            name="name"
            type="password"
            @focus="focusInput"
            @blur="blurInput($event); $v.profileEdit.password.$touch()"
            @input="typedInput"
            @change="typedInput"
            v-model="profileEdit.password"
            :class="{'error': errorConfirm}"
          >
          <label>Password</label>
        </div>
        <div v-if="errorConfirm" class="input-error">{{errorConfirm}}</div>
      </div>
      <div class="btn-wrapper right">
        <button
          class="btn btn-secondary"
          @click="closeConfirm"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary btn-loader"
          :class="{'disable': $v.profileEdit.password.$error, 'loader': editProfileLoading}"
          @click="saveProfileEdit()"
        >
          Save
        </button>
      </div>
    </pop-up>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PopUp from '@/components/PopUp'
export default {
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      activeItem: 'tab-1',
      profileEdit: JSON.parse(JSON.stringify(this.content.profile)),
      editProfileShow: true,
      loading_userslist_client: true,
      showConfirm: false,
      errorConfirm: '',
      editProfileLoading: false
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    closeConfirm: function () {
      this.showConfirm = false
      this.$v.profileEdit.password.$reset()
      this.profileEdit.password = ''
      this.errorConfirm = ''
    },
    saveProfileEdit: function () {
      this.$v.profileEdit.password.$touch()
      if (!this.$v.profileEdit.password.$error) {
        this.editProfileLoading = true
        this.$store.dispatch('editProfileClient', this.profileEdit)
          .then((r) => {
            this.editProfileLoading = false
            if (r.data.status) {
              this.errorConfirm = ''
              this.showConfirm = false
              this.$emit('update', this.profileEdit.cl_id)
            } else {
              this.errorConfirm = r.data.message
            }
          })
          .catch((e) => {
            this.editProfileLoading = false
            console.log(e)
          })
      }
    }
  },
  mounted () {
    console.log(this.profileEdit)
  },
  validations: {
    profileEdit: {
      fullname: {
        required
      },
      password: {
        required
      }
    }
  },
  components: {
    PopUp
  }
}
</script>

<style lang="scss">
.more-info-edit{
  &-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-in{
      margin-right: 15px;
    }
  }
}
</style>
