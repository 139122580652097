<template>
  <div v-if="content">
    <ul class="tabs-nav type-two">
      <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">General information</li>
      <!--
      <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">Statistics</li>
      -->
      <li @click.prevent="setActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">Profile</li>
      <li @click.prevent="setActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">Settings</li>
    </ul>
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">
      <div class="lb-mb-20">
        <div class="h3 lb-mb-2">About person</div>
        <div class="color-grey lb-mb-15">Basic information about Cliffton Wells</div>
        <div class="more-info-layout">
          <table class="table table-mod text-md">
            <tbody>
            <tr>
              <td>Role</td>
              <td><span class="text-media color-dark">{{content.general.role}}</span></td>
            </tr>
            <tr>
              <td>Debt</td>
              <td>
                <div class="mr-in-text">
                  <span class="color-red text-in">€{{content.general.debt}}</span>
                  <button class="btn btn-outlined">Pay for work</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>Date of registration</td>
              <td><span class="text-media color-dark">{{dateDay(content.general.created).date}}<span class="color-grey-2"> • </span>{{dateDay(content.general.created).hours}} : {{dateDay(content.general.created).minutes}}</span></td>
            </tr>
            <tr>
              <td>Last activity</td>
              <td><span class="text-media color-dark">{{dateDay(content.general.last_active).date}}<span class="color-grey-2"> • </span>{{dateDay(content.general.last_active).hours}} : {{dateDay(content.general.last_active).minutes}}</span></td>
            </tr>
            <tr>
              <td>Reviews</td>
              <td>
                <div class="mr-in-rating text-md">
                  <span class="icon"><img src="@/assets/img/icons/icon-star.svg" alt="icon"></span>
                  <span class="color-dark">{{content.general.stars}}</span><span class="color-grey"> ({{content.general.reviews}} reviews)</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Complaints</td>
              <td><span class="text-media color-dark">{{content.general.complaints}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--
      <div>
        <div class="h3 lb-mb-2">User activity</div>
        <div class="color-grey lb-mb-15">Displaying thecoach's activity working with the clients</div>
        <div class="more-info-layout">
          <line-chart-more-info
            :info="info"
            color-border-chart="#02BB9F"
            color-bg-chart="rgba(1,1,1,0)"
          >
          </line-chart-more-info>
        </div>
      </div>
      -->
    </div>
    <!--
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">
      <div class="lb-mb-8">
        <div class="lb-mb-15">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto">
              <div class="h3">Meeting statistics</div>
            </div>
            <div class="col-auto">
              <div class="btn-group bg-gray">
                <button class="btn btn-ghost dark-gray active">Today</button>
                <button class="btn btn-ghost dark-gray">Upcoming</button>
                <button class="btn btn-ghost dark-gray">Past</button>
              </div>
            </div>
          </div>
        </div>
        <div class="more-info-layout">
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
          <div class="thumbnail-stepper">
            <div class="text-sm color-dark title">
              <span class="badge-ml bg-gr2 sm">Sam Miller</span> invited to a meeting <span class="badge-ml bg-gr2 sm">Clifton Wells</span>
            </div>
            <div class="text-sm color-grey">15 Jun 2021, 11:23 AM</div>
          </div>
        </div>
      </div>
      <div class="text-sm color-grey">Appointed: <span class="color-green2">5</span></div>
    </div>
    -->
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">
      <div class="row row-24 nowrap">
        <div class="col-w-100">
          <div class="lb-mb-15">
            <div class="color-dark lb-mb-4 h2">{{content.profile.name}}</div>
            <div v-if="content.general.role" class="color-grey">{{content.general.role}}</div>
          </div>
          <div v-if="content.profile.tags.length > 0" class="lb-mb-15">
            <div class="badge-ml-wrapper">
                <span
                  class="badge-ml bg-grey color-dark-2"
                  v-for="(item, i) in content.profile.tags"
                  :key="i + 'po'"
                >
                  {{item.title}}
                </span>
            </div>
          </div>
          <div v-if="content.profile.background" class="lb-mb-15 color-dark">
            {{content.profile.background}}
          </div>
        </div>
        <div class="col">
          <div class="lab-dash-image-user size-three">
            <img v-if="content.profile.photo" :src="content.profile.photo" alt="image">
            <img v-else src="@/assets/img/default-user-avatar.png" alt="iamge">
          </div>
        </div>
      </div>
      <div>
        <ul class="tabs-nav type-two no-br">
          <li @click.prevent="setActiveTwo('tab-1')" :class="{ 'is-active': isActiveTwo('tab-1') }">Personal info</li>
          <li @click.prevent="setActiveTwo('tab-2')" :class="{ 'is-active': isActiveTwo('tab-2') }">Own projects</li>
        </ul>
        <div>
          <div class="tabs-container lb-tp-15" :class="{ 'is-active': isActiveTwo('tab-1') }">
            <div v-if="content.profile" class="lb-mb-15">
              <table class="table min-first-col text-md">
                <tbody>
                <tr v-if="content.profile.email">
                  <td>Email</td>
                  <td><span class="text-media color-dark">{{content.profile.email}}</span></td>
                </tr>
                <tr v-if="content.profile.phone">
                  <td>Phone</td>
                  <td><span class="text-media color-dark">{{content.profile.phone}}</span></td>
                </tr>
                <tr v-if="content.profile.birthday">
                  <td>Birthday</td>
                  <td><span class="text-media color-dark">{{dateDay(content.profile.birthday).date}}</span></td>
                </tr>
                <tr v-if="content.profile.location">
                  <td>Location</td>
                  <td><span class="text-media color-dark">{{content.profile.location}}</span></td>
                </tr>
                <tr v-if="content.profile.langs.length > 0">
                  <td>Languages</td>
                  <td>
                  <span class="text-media color-dark">
                    <span
                      v-for="(item, i) in content.profile.langs"
                      :key="i + 'so'"
                    >
                      {{item}}<span v-if="content.profile.langs.length > (i + 1)">,</span>
                    </span>
                  </span>
                  </td>
                </tr>
                <tr v-if="content.profile.ln">
                  <td>LinkedIn</td>
                  <td><a :href="content.profile.ln" class="btn btn-link">{{content.profile.ln}}</a></td>
                </tr>
                <tr v-if="content.profile.fb">
                  <td>Facebook</td>
                  <td><a :href="content.profile.fb" class="btn btn-link">{{content.profile.fb}}</a></td>
                </tr>
                <tr v-if="content.profile.inst">
                  <td>Instagram</td>
                  <td><a :href="content.profile.inst" class="btn btn-link">{{content.profile.fb}}</a></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="content.achievments" class="lb-mb-15">
              <div class="text-media h4 lb-mb-15">Information</div>
              <table class="table min-first-col text-md">
                <tbody>
                <tr v-if="content.achievments.since">
                  <td>Coach since</td>
                  <td><span class="text-media color-dark"> {{dateDay(content.achievments.since).date}}</span></td>
                </tr>
                <tr v-if="content.achievments.school">
                  <td>Name of coaching school</td>
                  <td><span class="text-media color-dark">{{content.achievments.school}}</span></td>
                </tr>
                <tr v-if="content.certifications && content.certifications.length">
                  <td>Coaching certification</td>
                  <td>
                    <span class="text-media color-dark">
                        <span
                          class="info-list-c"
                          v-for="(item, i) in content.certifications"
                          :key="i + 's'"
                        >
                          {{item.certs.title}}<span>, </span>
                        </span>
                      </span>
                  </td>
                </tr>
                <tr v-if="content.achievments.feds">
                  <td>Coaching federation member</td>
                  <td><span class="text-media color-dark">{{content.achievments.feds.title}}</span></td>
                </tr>
                <tr v-if="content.diplom && content.diplom.length">
                  <td>Coaching school diploma as business coach</td>
                  <td><a :href="content.diplom.diplom_url" class="btn btn-link"><span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span><span>{{content.diplom.diplom_name}}</span></a></td>
                </tr>
                <tr v-if="content.achievments.federation_role">
                  <td>Do you have a role in a coaching federation?</td>
                  <td><span class="text-media color-dark">{{content.achievments.federation_role}}</span></td>
                </tr>
                <tr v-if="content.achievments.management_experience">
                  <td>Years of management experience</td>
                  <td><span class="text-media color-dark">{{content.achievments.management_experience}}</span></td>
                </tr>
                <tr v-if="content.achievments.leadership_experience">
                  <td>Years of leadership experience</td>
                  <td><span class="text-media color-dark">{{content.achievments.leadership_experience}}</span></td>
                </tr>
                <tr v-if="content.achievments.led_a_group">
                  <td>How many years have you led a group of people or an organisation?</td>
                  <td><span class="text-media color-dark">{{content.achievments.led_a_group}}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tabs-container lb-tp-15" :class="{ 'is-active': isActiveTwo('tab-2') }">
            <div v-if="content.projects.length > 0" class="row row-8 card-wrap">
              <div class="col-lg-6" v-for="(item, i) in content.projects" :key="i">
                <div class="card type-two bg-white">
                  <div class="card-header">
                    <div class="card-image">
                      <img :src="item.image" alt="image">
                    </div>
                    <div v-if="item.link" class="lb-mb-15">
                      <div class="badge-ml-wrapper">
                  <span class="badge-ml bg-grey color-dark-2 text-sm badge-icon">
                    <span class="icon"><img src="@/assets/img/icons/icon-link.svg" alt="icon"></span>
                    <span class="link" v-html="item.link"></span>
                  </span>
                      </div>
                    </div>
                    <div class="card-title lb-mb-8 h4"><span class="max-l-b">{{item.title}}</span></div>
                    <div class="card-text text-md color-grey text-with-editor">
                      <div v-html="item.body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>None</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-container lb-tp-25" v-if="isActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">
      <div class="h3 lb-mb-8">Status account</div>
      <div class="btn-wrapper sm">
        <button class="btn btn-secondary is-active">Active</button>
        <button class="btn btn-secondary">Inactive</button>
        <button class="btn btn-secondary">Block</button>
      </div>
    </div>
  </div>
</template>

<script>
// import LineChartMoreInfo from '@/components/LineChartMoreInfo'
export default {
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      activeItem: 'tab-1',
      activeItemTwo: 'tab-1',
      info: [
        {
          month: 'Jan',
          quantity: 0
        },
        {
          month: 'Feb',
          quantity: 150
        },
        {
          month: 'Mar',
          quantity: 210
        },
        {
          month: 'Apr',
          quantity: 280
        },
        {
          month: 'May',
          quantity: 300
        },
        {
          month: 'Jun',
          quantity: 380
        }
      ]
    }
  },
  methods: {
    isActive (e) {
      return this.activeItem === e
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    isActiveTwo (e) {
      return this.activeItemTwo === e
    },
    setActiveTwo (menuItem) {
      this.activeItemTwo = menuItem
    },
    dateDay (e) {
      const t = new Date(e)
      const date = ('0' + t.getDate()).slice(-2) + ' ' + this.getNameMonth(t.getMonth()) + ', ' + t.getFullYear()
      const d = {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth()),
        hours: ('0' + t.getHours()).slice(-2),
        minutes: ('0' + t.getMinutes()).slice(-2)
      }
      return d
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    }
  },
  components: {
    // LineChartMoreInfo
  }
}
</script>

<style lang="scss">
.lab-dash{
  &-image-user{
    width: 138px;
    height: 138px;
    line-height: 0;
    border-radius: 16px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}
.mr-in-rating{
  .icon {
    margin-right: 5px;
    display: inline-block;
    width: 20px;
    line-height: 0;
    position: relative;
    top: -1px;
  }
  span {
    vertical-align: middle;
  }
}
.mr-in-text{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text-in{
    margin-right: 15px;
    max-width: 50%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.block-user{
  display: flex;
  align-items: center;
  .image{
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name{
    padding-left: 8px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.more-info-layout{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.table.table-mod{
  tr:first-child{
    td{
      padding-top: 0;
    }
  }
  tr:last-child{
    td{
      padding-bottom: 0;
    }
  }
}
.info-user-wrapper{
  display: flex;
  .info{
    width: 100%;
    padding-right: 40px;
  }
  .image{
    width: 120px;
    height: 120px;
    flex: 0 0 120px;
    border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
.thumbnail-supp{
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-bottom: 4px;
  .head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .status{
    position: relative;
    padding-left: 12px;
    max-width: 40%;
    margin-right: 15px;
    &:after{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #161616;
    }
  }
  .status,
  .time{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.green{
    .status{
      &:after{
        background-color: #02BB9F;
      }
    }
  }
  &.red{
    .status{
      &:after{
        background-color: #FA665B;
      }
    }
  }
}
.thumbnail-stepper{
  padding-left: 18px;
  position: relative;
  padding-bottom: 24px;
  &:after{
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 0;
    bottom: -5px;
  }
  &:before{
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #5E4BF1;
    position: absolute;
    left: 0;
    top: 5px;
    transform: translateX(-50%);
    z-index: 2;
  }
  .title{
    margin-bottom: 8px;
  }
  &:last-child{
    padding-bottom: 0;
    &:after{
      display: none;
    }
  }
}
</style>
