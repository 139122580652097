<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Administration Jobs</div>
      <ul class="header-nav">
        <router-link tag="li" active-class="is-active" to="/jobs/professions"><span>Professions</span><span class="nv-badge">681</span></router-link>
        <router-link tag="li" active-class="is-active" to="/jobs/vacancies"><span>Vacancies</span><span class="nv-badge">681</span></router-link>
      </ul>
      <div class="header-rg">
        <button
          class="btn btn-primary"
          @click="addNewVacancy"
        >
          Add vacancy
        </button>
      </div>
    </header>
    <div class="lb-mb-15">
      <div class="input-wrapper-icon clean">
        <span class="icon"><img src="@/assets/img/icons/icon-search-light.svg" alt="icon-search"></span>
        <button
          class="icon-clean"
          v-if="searchVacancy.length"
          @click="searchVacancy = ''"
        >
          <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
        </button>
        <input class="input light sm" placeholder="Search for anything" type="text" v-model="searchVacancy">
      </div>
    </div>
    <div class="block-layout block-layout-vacanc height-js" data-bt="24">
      <div class="vacanc-wrapper">
        <div class="vacanc-list">
          <div class="vacanc-list-header lb-mb-15">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto">
                <div class="dropdown drop-select min-a">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Most relevant</button>
                  <ul class="dropdown-inner">
                    <li>Label 1</li>
                    <li>Label 2</li>
                    <li>Label 3</li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="text-md">Found {{vacancies.length}} jobs</div>
              </div>
            </div>
          </div>
          <div class="vacanc-list-wrapper height-js no-min" data-bt="50">
            <div v-if="resultVacancies.length > 0">
              <div
                class="vacanc-list-item"
                :class="{'active': vacancyActive === i}"
                v-for="(item, i) in resultVacancies"
                :key="i + 'a'"
                @click="vacancyActive = i"
              >
                <div class="image">
                  <img src="@/assets/img/com-image-09.png" alt="image">
                </div>
                <div class="info">
                  <div class="text-sm name">{{item.nameCompany}}</div>
                  <div class="text-md vacanc">{{item.vacancy}}</div>
                  <div class="text-sm city">
                    <span class="icon"><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.3C5.4 1.3 3.3 3.4 3.3 6c0 3.5 4.7 8.7 4.7 8.7s4.7-5.2 4.7-8.7c0-2.6-2.1-4.7-4.7-4.7zM4.7 6a3.3 3.3 0 016.6 0c0 2-1.9 4.8-3.3 6.6C6.6 10.8 4.7 7.9 4.7 6z" class="fill-blue"/><path d="M8 7.7a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4z" class="fill-blue"/></svg></span>
                    <span class="city-text">{{item.city}}</span>
                  </div>
                </div>
                <span class="like">
                  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 3A6 6 0 0012 5a6 6 0 00-4.5-2C4.5 3 2 5.4 2 8.5c0 3.8 3.4 6.9 8.6 11.5l1.4 1.4 1.4-1.4c5.2-4.6 8.6-7.7 8.6-11.5 0-3-2.4-5.5-5.5-5.5zm-4.4 15.6h-.2C7.1 14.2 4 11.4 4 8.5 4 6.5 5.5 5 7.5 5c1.5 0 3 1 3.6 2.4h1.8C13.5 6 15 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.9-3.1 5.7-7.9 10z" class="fill-blue"/></svg>
                </span>
                <div class="rating text-md">
                  <span>{{item.rating}}</span>
                  <span class="icon"><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 11.5l4.1 2.5-1-4.7 3.6-3.1-4.8-.4L8 1.3 6.1 5.8l-4.8.4L5 9.3 3.9 14 8 11.5z" class="fill-orang"/></svg></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vacanc-detailed height-js no-min" data-bt="25">
          <div v-if="vacanciesDetail">
            <div class="vacanc-detailed-header">
              <div class="name">
                <span class="color-dark">{{vacanciesDetail.nameCompany}}</span>
                <div class="name-rating">
                  <span>{{vacanciesDetail.rating}}</span>
                  <span class="icon">
                          <img src="@/assets/img/icons/icon-star-09.svg" alt="icon">
                        </span>
                </div>
              </div>
              <div class="h3 vacanc">
                {{vacanciesDetail.vacancy}}
              </div>
              <div class="city text-md">
                <span class="icon"><img src="@/assets/img/icons/icon-location_on.svg" alt="icon"></span>
                <span>{{vacanciesDetail.city}}</span>
              </div>
            </div>
            <ul class="tabs-nav type-two">
              <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">Job</li>
              <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">Company</li>
            </ul>
            <div>
              <div class="tabs-container lb-tp-15" :class="{ 'is-active': isActive('tab-1') }">
                <div class="text text-md" v-html="vacanciesDetail.detail"></div>
              </div>
              <div class="tabs-container lb-tp-15" :class="{ 'is-active': isActive('tab-2') }">
                <div>tab 2</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeModal"
    >
      <div class="h2 color-dark lb-mb-40">Add vacancy</div>
      <div v-if="vacanciesEdit">
        <div class="h4 lb-mb-2">General information</div>
        <div class="text-md color-grey lb-mb-15">Fill in the required information about the vacancy</div>
        <div class="form-group md lb-mb-20">
          <div class="input-wrapper-material">
            <div
              class="input-inner"
              :class="{'typed': vacanciesEdit.name}"
            >
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.vacanciesEdit.name.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="vacanciesEdit.name"
                :class="{'error': $v.vacanciesEdit.name.$error}"
              >
              <label>Name of vacancy</label>
            </div>
            <div v-if="$v.vacanciesEdit.name.$error" class="input-error">Error</div>
          </div>
          <div class="input-wrapper-material textarea">
            <div
              class="input-inner"
              :class="{'typed': vacanciesEdit.description}"
            >
                <textarea
                  class="input md input-material typed"
                  :class="{'error': $v.vacanciesEdit.description.$error}"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.vacanciesEdit.description.$touch()"
                  @input="typedInput"
                  v-model="vacanciesEdit.description"
                ></textarea>
              <label>Description</label>
            </div>
            <div v-if="$v.vacanciesEdit.description.$error" class="input-error">Error</div>
          </div>
          <div class="row row-8">
            <div class="col-lg-6">
              <div
                class="sl-select-wrapper-material"
                :class="{'typed': vacanciesEdit.location.length > 0}"
              >
                <multiselect
                  v-model="vacanciesEdit.location"
                  :options="optionsLocation"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder=""
                >
                </multiselect>
                <label>Location</label>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="sl-select-wrapper-material"
                :class="{'typed': vacanciesEdit.placeWork.length > 0}"
              >
                <multiselect
                  v-model="vacanciesEdit.placeWork"
                  :options="optionsPlaceWork"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder=""
                >
                </multiselect>
                <label>Place of work</label>
              </div>
            </div>
          </div>
        </div>
        <div class="lb-mb-20">
          <div class="h4 lb-mb-2">Salary, $</div>
          <div class="text-md color-grey lb-mb-15">Fill in the required fields to describe the event</div>
          <div class="row row-8">
            <div class="col-lg-6">
              <div class="input-wrapper-material">
                <div
                  class="input-inner"
                  :class="{'typed': vacanciesEdit.salary.min}"
                >
                  <input
                    class="input input-material"
                    name="name"
                    type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.vacanciesEdit.salary.min.$touch()"
                    @input="typedInput"
                    v-model="vacanciesEdit.salary.min"
                    :class="{'error': $v.vacanciesEdit.salary.min.$error}"
                  >
                  <label>From salary ($)</label>
                </div>
                <div v-if="$v.vacanciesEdit.salary.min.$error" class="input-error">Error</div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-wrapper-material">
                <div
                  class="input-inner"
                  :class="{'typed': vacanciesEdit.salary.max}"
                >
                  <input
                    class="input input-material"
                    name="name"
                    type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.vacanciesEdit.salary.max.$touch()"
                    @input="typedInput"
                    v-model="vacanciesEdit.salary.max"
                    :class="{'error': $v.vacanciesEdit.salary.max.$error}"
                  >
                  <label>Before salary ($)</label>
                </div>
                <div v-if="$v.vacanciesEdit.salary.max.$error" class="input-error">Error</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lb-mb-40">
          <div class="h4 lb-mb-2">Company</div>
          <div class="text-md color-grey lb-mb-15">Enter company information</div>
          <div class="form-group md">
            <div class="input-wrapper-material">
              <div
                class="input-inner"
                :class="{'typed': vacanciesEdit.nameCompany}"
              >
                <input
                  class="input input-material"
                  name="name"
                  type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.vacanciesEdit.nameCompany.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="vacanciesEdit.nameCompany"
                  :class="{'error': $v.vacanciesEdit.nameCompany.$error}"
                >
                <label>Name of company</label>
              </div>
              <div v-if="$v.vacanciesEdit.nameCompany.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div
                class="input-inner"
                :class="{'typed': vacanciesEdit.websiteCompany}"
              >
                <input
                  class="input input-material"
                  name="name"
                  type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.vacanciesEdit.websiteCompany.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="vacanciesEdit.websiteCompany"
                  :class="{'error': $v.vacanciesEdit.websiteCompany.$error}"
                >
                <label>Company website</label>
              </div>
              <div v-if="$v.vacanciesEdit.websiteCompany.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material textarea">
              <div
                class="input-inner"
                :class="{'typed': vacanciesEdit.descriptionCompany}"
              >
                <textarea
                  class="input md input-material typed"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="vacanciesEdit.descriptionCompany"
                ></textarea>
                <label>Description</label>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary"
            :class="{'disable': $v.$invalid}"
            @click="sendVacancies"
          >
            Add vacancy
          </button>
          <button
            class="btn btn-secondary"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import PopUp from '@/components/PopUp'
import { required, url } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
export default {
  data () {
    return {
      showModal: false,
      vacanciesEdit: '',
      vacanciesModel: {
        name: '',
        description: '',
        location: '',
        placeWork: '',
        salary: {
          min: '',
          max: ''
        },
        nameCompany: '',
        websiteCompany: '',
        descriptionCompany: ''
      },
      optionsLocation: ['Kyiv', 'Lviv', 'Odesa', 'Kharkiv', 'Chernivtsi'],
      optionsPlaceWork: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
      searchVacancy: '',
      vacancies: [
        {
          nameCompany: 'Microsoft',
          vacancy: 'Middle Front-End Developer',
          city: 'United States',
          rating: 4.1,
          detail: `<p>At Verbal+Visual, we craft considered e-commerce experiences for thoughtful brands. Brands that put people and product over profit and pollution. Brands that boldly tell their story, build community, and empower artisans. Brands that believe in the power of the collective. Together, we make commerce that matters.</p>
                      <p>Verbal+Visual is seeking a thoughtful and holistically-minded Front End Web Developer. In this role you will be responsible for building e-commerce websites using Shopify, Rails, and Vue.js. You’ll need to take work with cross-functional groups to maintain operational and development guidelines and processes developing and maintaining desktop and mobile application user interface components and mobile platforms.</p>
                      <h4>Primary Responsibilities:</h4>
                      <ul>
                        <li>Participate in all aspects of software development lifecycle, including requirements analysis, architecture, design, programming, deployment, and maintenance. </li>
                        <li>Design, code, and test applications and user interfaces.</li>
                        <li>Convert Sketch files into fully functioning e-commerce sites using Shopify Plus.</li>
                        <li>Perform thorough QA testing including cross-browser/platform compatibility with a design point of view in mind.</li>
                        <li>Layout content and images following design style guides.</li>
                        <li>Prepare detailed specifications from which programs will be written and designed, coded, tested, debugged and document those programs.</li>
                        <li>Perform project planning, organizing and implementation until the application is commissioned in a production environment.</li>
                        <li>Must live within commuting distance of Manhattan, New York.</li>
                      </ul>
                   `,
          like: false,
          remoteOptions: ['Remote only'],
          employmentType: ['Full-time', 'Part-time'],
          postedDate: ['Any Date'],
          salaryRange: ['Before 1,000$'],
          industry: ['Accounting & Legal']
        },
        {
          nameCompany: 'IBM',
          vacancy: 'Senior Front-End Developer',
          city: 'United States',
          rating: 4.2,
          detail: `<p>At Verbal+Visual, we craft considered e-commerce experiences for thoughtful brands. Brands that put people and product over profit and pollution. Brands that boldly tell their story, build community, and empower artisans. Brands that believe in the power of the collective. Together, we make commerce that matters.</p>
                      <p>Verbal+Visual is seeking a thoughtful and holistically-minded Front End Web Developer. In this role you will be responsible for building e-commerce websites using Shopify, Rails, and Vue.js. You’ll need to take work with cross-functional groups to maintain operational and development guidelines and processes developing and maintaining desktop and mobile application user interface components and mobile platforms.</p>
                      <h4>Primary Responsibilities:</h4>
                      <ul>
                        <li>Participate in all aspects of software development lifecycle, including requirements analysis, architecture, design, programming, deployment, and maintenance. </li>
                        <li>Design, code, and test applications and user interfaces.</li>
                        <li>Convert Sketch files into fully functioning e-commerce sites using Shopify Plus.</li>
                        <li>Perform thorough QA testing including cross-browser/platform compatibility with a design point of view in mind.</li>
                        <li>Layout content and images following design style guides.</li>
                        <li>Prepare detailed specifications from which programs will be written and designed, coded, tested, debugged and document those programs.</li>
                        <li>Perform project planning, organizing and implementation until the application is commissioned in a production environment.</li>
                        <li>Must live within commuting distance of Manhattan, New York.</li>
                      </ul>
                   `,
          like: false,
          remoteOptions: ['Remote only'],
          employmentType: ['Full-time'],
          postedDate: ['Any Date'],
          salaryRange: ['Before 1,000$'],
          industry: ['Accounting & Legal']
        },
        {
          nameCompany: 'PayPal',
          vacancy: 'Senior Front-End Developer',
          city: 'United States',
          rating: 4.3,
          detail: `<p>At Verbal+Visual, we craft considered e-commerce experiences for thoughtful brands. Brands that put people and product over profit and pollution. Brands that boldly tell their story, build community, and empower artisans. Brands that believe in the power of the collective. Together, we make commerce that matters.</p>
                      <p>Verbal+Visual is seeking a thoughtful and holistically-minded Front End Web Developer. In this role you will be responsible for building e-commerce websites using Shopify, Rails, and Vue.js. You’ll need to take work with cross-functional groups to maintain operational and development guidelines and processes developing and maintaining desktop and mobile application user interface components and mobile platforms.</p>
                      <h4>Primary Responsibilities:</h4>
                      <ul>
                        <li>Participate in all aspects of software development lifecycle, including requirements analysis, architecture, design, programming, deployment, and maintenance. </li>
                        <li>Design, code, and test applications and user interfaces.</li>
                        <li>Convert Sketch files into fully functioning e-commerce sites using Shopify Plus.</li>
                        <li>Perform thorough QA testing including cross-browser/platform compatibility with a design point of view in mind.</li>
                        <li>Layout content and images following design style guides.</li>
                        <li>Prepare detailed specifications from which programs will be written and designed, coded, tested, debugged and document those programs.</li>
                        <li>Perform project planning, organizing and implementation until the application is commissioned in a production environment.</li>
                        <li>Must live within commuting distance of Manhattan, New York.</li>
                      </ul>
                   `,
          like: false,
          remoteOptions: ['Remote only'],
          employmentType: ['Full-time', 'Part-time'],
          postedDate: ['Any Date'],
          salaryRange: ['Before 1,000$'],
          industry: ['Accounting & Legal']
        },
        {
          nameCompany: 'Apple',
          vacancy: 'Lead Front-End Developer',
          city: 'United States',
          rating: 4.5,
          detail: `<p>At Verbal+Visual, we craft considered e-commerce experiences for thoughtful brands. Brands that put people and product over profit and pollution. Brands that boldly tell their story, build community, and empower artisans. Brands that believe in the power of the collective. Together, we make commerce that matters.</p>
                      <p>Verbal+Visual is seeking a thoughtful and holistically-minded Front End Web Developer. In this role you will be responsible for building e-commerce websites using Shopify, Rails, and Vue.js. You’ll need to take work with cross-functional groups to maintain operational and development guidelines and processes developing and maintaining desktop and mobile application user interface components and mobile platforms.</p>
                      <h4>Primary Responsibilities:</h4>
                      <ul>
                        <li>Participate in all aspects of software development lifecycle, including requirements analysis, architecture, design, programming, deployment, and maintenance. </li>
                        <li>Design, code, and test applications and user interfaces.</li>
                        <li>Convert Sketch files into fully functioning e-commerce sites using Shopify Plus.</li>
                        <li>Perform thorough QA testing including cross-browser/platform compatibility with a design point of view in mind.</li>
                        <li>Layout content and images following design style guides.</li>
                        <li>Prepare detailed specifications from which programs will be written and designed, coded, tested, debugged and document those programs.</li>
                        <li>Perform project planning, organizing and implementation until the application is commissioned in a production environment.</li>
                        <li>Must live within commuting distance of Manhattan, New York.</li>
                      </ul>
                   `,
          like: false,
          remoteOptions: ['Remote only'],
          employmentType: ['Full-time', 'Part-time'],
          postedDate: ['Any Date'],
          salaryRange: ['Before 1,000$'],
          industry: ['Accounting & Legal']
        }
      ],
      activeItem: 'tab-1',
      isShow: true,
      vacancyActive: 0
    }
  },
  computed: {
    vacanciesDetail: function () {
      if (this.resultVacancies) {
        return this.resultVacancies[this.vacancyActive]
      } else {
        return ''
      }
    },
    resultVacancies: function () {
      return this.vacancies.filter(val => {
        if (this.searchVacancy === '') {
          return true
        } else {
          return val.nameCompany.toLowerCase().includes(this.searchVacancy.toLowerCase())
        }
      })
    }
  },
  methods: {
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    isActive: function (e) {
      return this.activeItem === e
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    closeModal: function () {
      this.showModal = false
      this.vacanciesEdit = ''
    },
    addNewVacancy: function () {
      this.vacanciesEdit = JSON.parse(JSON.stringify(this.vacanciesModel))
      this.showModal = true
    },
    sendVacancies: function () {
      console.log(this.vacanciesEdit)
      this.vacanciesEdit = ''
      this.showModal = false
    }
  },
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
  },
  updated () {
    this.$utils.scrollHG()
  },
  validations: {
    vacanciesEdit: {
      name: {
        required
      },
      description: {},
      salary: {
        min: {
          required
        },
        max: {
          required
        }
      },
      nameCompany: {
        required
      },
      websiteCompany: {
        url
      }
    }
  },
  components: {
    PopUp,
    Multiselect
  }
}
</script>
