import store from '../store'

export default function (to, from, next) {
  if (to.query.usr) {
    store.dispatch('loginUser', to.query.usr).then((r) => {
      checking(next)
    }, (error) => {
      window.location.replace(store.getters.loginURL)
      console.log(error)
    })
  } else {
    checking(next)
  }
}

function checking (next) {
  if (store.getters.userSecurity) {
    next()
  } else {
    window.location.replace(store.getters.loginURL)
  }
}
