import axios from 'axios'

export default {
  state: {
    userslist: '',
    info_userslist_coach: '',
    info_userslist_client: '',
    counts_users: ''
  },
  mutations: {
    updateUserslist (state, payload) {
      state.userslist = payload
    },
    updateUserslistCoach (state, payload) {
      state.info_userslist_coach = payload
    },
    cleanseUserslistCoach (state, payload) {
      state.info_userslist_coach = ''
    },
    updateUserslistClient (state, payload) {
      state.info_userslist_client = payload
    },
    cleanseUserslistClient (state, payload) {
      state.info_userslist_client = ''
    },
    updateCountsUsers (state, payload) {
      state.counts_users = payload
    }
  },
  actions: {
    async fetchUserslist ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getuserslist',
          params: {
            page: payload.current_page,
            account: payload.account,
            search: payload.search,
            sort: payload.sort,
            direction: payload.direction
          }
        })
        commit('updateUserslist', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchUserslistType ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getuserslistbytype',
          params: {
            page: payload.current_page,
            type: payload.type,
            account: payload.account,
            search: payload.search,
            sort: payload.sort,
            direction: payload.direction
          }
        })
        commit('updateUserslist', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchUserslistNew ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getnewuserslist',
          params: {
            page: payload.current_page,
            type: payload.type,
            account: payload.account,
            search: payload.search
          }
        })
        commit('updateUserslist', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchUserslistCoach ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getcoachinfo',
          params: {
            id: payload
          }
        })
        commit('updateUserslistCoach', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchUserslistClient ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getclientinfo',
          params: {
            id: payload
          }
        })
        commit('updateUserslistClient', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async changeCoachSave ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/coachassign',
          params: {
            coach_id: payload.coach_id,
            client_id: payload.client_id
          }
        })
        commit('updateUserslistClient', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchCountsUsers ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/getusercounts'
        })
        commit('updateCountsUsers', x.data)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
      }
    },
    async editProfileClient ({ commit, getters }, payload) {
      try {
        const d = {
          user_id: payload.cl_id,
          id: payload.cl_id,
          password: payload.password,
          fullname: payload.fullname ? payload.fullname : '',
          email: payload.email ? payload.email : '',
          phone: payload.phone ? payload.phone : '',
          location: payload.location ? payload.location : '',
          source: payload.source ? payload.source : '',
          сhannel: payload.сhannel ? payload.сhannel : ''

        }
        const x = await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/clientinfo',
          data: d
        })
        return x
      } catch (error) {
        console.log(error.response)
        throw error
      }
    },
    async deleteProfileClient ({ commit, getters }, payload) {
      try {
        const x = await axios({
          method: 'delete',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/client',
          data: payload
        })
        return x
      } catch (error) {
        console.log(error.response)
        throw error
      }
    }
  },
  getters: {
    userslist (state) {
      return state.userslist
    },
    info_userslist_coach (state) {
      return state.info_userslist_coach
    },
    info_userslist_client (state) {
      return state.info_userslist_client
    },
    counts_users (state) {
      return state.counts_users
    }
  }
}
