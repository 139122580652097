import General from './general'
import Professions from './professions'
import Today from './today'
export default {
  modules: {
    General,
    Professions,
    Today
  }
}
