export const utils = {
  scrollHG: function () {
    const $e = document.querySelectorAll('.height-js')
    $e.forEach((e) => {
      const t = e.getBoundingClientRect().top + pageYOffset
      const wh = window.innerHeight
      if (e.closest('.no-min')) {
        e.style.height = wh - (t + 24) + 'px'
      } else {
        e.style.minHeight = wh - (t + 24) + 'px'
      }
    })
  },
  blockScroll: function () {
    document.querySelector('html').style.overflow = 'hidden'
    document.querySelector('html').style.paddingRight = '5px'
  },
  unBlockScroll: function () {
    document.querySelector('html').style.removeProperty('overflow')
    document.querySelector('html').style.removeProperty('padding')
  }
}
