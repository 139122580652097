import axios from 'axios'

export default {
  state: {
    today_activity: ''
  },
  mutations: {
    updateTodayActivity (state, payload) {
      state.today_activity = payload
    }
  },
  actions: {
    async fetchTodayActivity ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const r = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/admin/dailystat'
        })
        commit('updateTodayActivity', r.data)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    today_activity (state) {
      return state.today_activity
    }
  }
}
